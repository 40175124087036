<template>
	<div class="editor-grid">
		<Toolbar
				ref="myToolbar"
				style="border-bottom: 1px solid #ccc"
				:editor="editor"
				:defaultConfig="toolbarConfig"
				:mode="mode"
				v-show="!datas.isdrag"
		/>
		<Editor
				:style="'height:'+top_height+'px;overflow-y: auto;'"
				v-model="datas.data.html"
				:defaultConfig="editorConfig"
				:mode="mode"
				@onCreated="onCreated"
				v-show="!datas.isdrag"
		/>
		<div v-show="datas.isdrag" v-html="datas.data.html"></div>
	</div>
</template>

<script>
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  export default {
		props: {
		  datas: Object,
		},
    data() {
      return {
				top_height: 20,
				editor: null,
				toolbarConfig: {
					// toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
					excludeKeys: [ 'blockquote','todo','insertLink','group-image','insertVideo','codeBlock','insertTable','fullScreen'/* 隐藏哪些菜单 */ ],
				},
				editorConfig: { placeholder: '请输入内容...' },
				mode: 'simple', // or 'simple'
      };
    },
    components: {
			Editor, Toolbar
    },
		watch: {
			datas: {
			  handler(val, old) {
					this.$nextTick(() => {
						const element = this.$refs.myToolbar;
						if (element) {
							const heightGrid = val.h*40 - 68;
							const height = element.$el.offsetHeight;
							let h = heightGrid-height
							this.top_height = h < 40 ? 40 : h;
						}
						
					});
			  },
			  deep: true,
			},
		},
		created() {
		},
		mounted() {
			
		},
    methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
    },
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
  };
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
	.editor-grid{
		height: 100%;
		display: flex;
		flex-direction: column;
	}
</style>