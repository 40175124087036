<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted () {
    // 取消开始的loading动画
    if (document.querySelector("#pre-loader")) {
      const preLoader = document.querySelector("#pre-loader");
      preLoader.style.display = "none";
    }
  },
  methods: {
    queryData () {
      return new Promise(function (resolve, reject) {
        resolve(12);
      });
    },
    someFunction () {
      setTimeout(() => {
        return 1000;
      }, 3000);
    },
    getData (data) {
      console.log(data, "232323");

      return data;
    },
  },
};
</script>

<style >
.el-popover {
  min-width: 50px !important;
}

.position-relative {
  position: relative;
}

p {
  margin: 0;
  padding: 0;
}
</style>
