const getters = {
  routeName: (state) => state.app.routeName,
  leftNav: (state) => state.app.leftNav,
  communicateNav: (state) => state.app.communicateNav,
  token: (state) => state.user.communicateNav,
  formInfo: (state) => state.app.formInfo,
  appName: (state) => state.app.appName,
  allMenberList: (state) => state.app.allMenberList,
  allDepartList: (state) => state.app.allDepartList,
  handValue:(state) => state.app.handValue
};
export default getters;
