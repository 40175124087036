import { render, staticRenderFns } from "./iframeDialog.vue?vue&type=template&id=685fe8df&scoped=true"
import script from "./iframeDialog.vue?vue&type=script&lang=js"
export * from "./iframeDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685fe8df",
  null
  
)

export default component.exports