
<template>
  <div class="dataLoad-warp" @click="handleDataLoad">
    <div>
      {{ data.__config__.label }}
    </div>
    <el-drawer
      title="数据加载"
      :append-to-body="true"
      :before-close="handleClose"
      :visible.sync="dataloaddrawer"
      direction="btt"
    >
      <el-table
        :data="dataTableList"
        ref="singleSelectTable"
        style="width: 100%"
      >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio
              v-model="selectedRows"
              :label="scope.row._id"
              @change="handleRadioChange(scope.row)"
            >
              &nbsp;
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in dataColumns"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
        ></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getDataLoadForm } from "@/api/app";
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      dataloaddrawer: false,
      selectedRows: null,
      dataColumns: [],
      dataTableList: [],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  props: ["data", "formDataFields", "formId"],
  components: {},
  created() {},
  methods: {
    getFormList(pagination) {
      let data = {
        field: this.data.__vModel__,
        form_id: this.formId,
        page: pagination.page,
        limit: pagination.limit,
      };
      getDataLoadForm(data).then((res) => {
        if (res.code == 200) {
          this.pagination.total = res.total;
          let list = res.data;
          let propArray = [];
          let dataArray = [];
          this.data.loadDataList.showFieldsList.forEach((item) => {
            list.forEach((item2) => {
              if (item.__vModel__ in item2) {
                propArray.push({
                  prop: item.__vModel__,
                  label: item.__config__.label,
                });
                dataArray.push({ tag: item.__config__.tag, ...item2 });
              }
            });
          });

          let dataList = this.uniqueByIdAndPreferDatePicker(dataArray);
          this.data.loadDataList.showFieldsList.forEach((item) => {
            if (
              item.__config__.tag === "menberCheck" ||
              item.__config__.tag === "menberSingle" ||
              item.__config__.tag === "departCheck" ||
              item.__config__.tag === "departSingle"
            ) {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];
                this.allMenberList.forEach((item2) => {
                  if (arr[0] == item2._id) {
                    arr[0] = item2.name;
                    item1["menberId"] = item2._id;
                  }
                });
              });
            }
          });
          this.dataTableList = dataList;
          //prop字段去重
          this.dataColumns = propArray.filter((item, index) => {
            return (
              propArray.findIndex((tab) => tab.prop === item.prop) === index
            );
          });
        }
      });
    },
    //数组方法
    uniqueByIdAndPreferDatePicker(arr) {
      const seenIds = new Set();
      const result = [];

      for (const item of arr) {
        if (!seenIds.has(item._id)) {
          // 如果当前_id还未被见过，直接添加到结果中
          result.push(item);
          seenIds.add(item._id);
        } else {
          // 如果当前_id已经被见过，检查是否需要替换
          const existingItem = result.find((x) => x._id === item._id);
          if (
            existingItem &&
            (item.tag === "menberCheck" ||
              item.tag === "menberSingle" ||
              item.tag === "departCheck" ||
              item.tag === "departSingle") &&
            (existingItem.tag !== "menberCheck" ||
              existingItem.tag !== "menberSingle" ||
              existingItem.tag !== "departCheck" ||
              existingItem.tag !== "departSingle")
          ) {
            // 如果新项的tag是'datePicker'，而现有项的tag不是，则替换现有项
            const index = result.indexOf(existingItem);
            result[index] = item;
          }
          // 如果新项的tag不是'datePicker'，或者现有项的tag已经是'datePicker'，则不做任何操作
        }
      }
      return result;
    },

    handleDataLoad() {
      if (
        this.data.loadDataList.showFieldsList.length == 0 ||
        this.data.loadDataList.choseList.length == 0 ||
        this.data.loadDataList.formName == ""
      ) {
        this.$message({
          message: "请联系应用管理员，设置数据加载的显示字段",
          type: "warning",
        });
      } else {
        this.dataloaddrawer = true;
        this.getFormList(this.pagination);
      }
    },
    handleClose() {
      this.dataloaddrawer = false;
    },

    handleRadioChange(row) {
      let handleArray = [];
      for (let key in row) {
        this.data.loadDataList.choseList.forEach((item) => {
          if (item.sourceValue == key) {
            let rowUse = row[key];
            if (Array.isArray(rowUse)) {
              handleArray.push({
                name: item.__config__.label,
                __vModel__: item.__vModel__,
                labelValue: {
                  label: rowUse[0],
                  id: row.menberId,
                },
              });
            } else {
              handleArray.push({
                name: item.__config__.label,
                labelValue: rowUse,
                __vModel__: item.__vModel__,
              });
            }
          }
        });
      }
      this.$emit("databack", handleArray);

      this.dataloaddrawer = false;
    },
    handleSizeChange(val) {
      this.pagination.limit = val;
      this.getFormList(this.pagination);
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getFormList(this.pagination);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["allMenberList"]),
  },
};
</script>

<style lang="scss" scoped>
.dataLoad-warp {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #999;
  height: 34px;
  line-height: 32px;
  border-radius: 4px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  border: 1px dashed #e0e0e0;
}
::v-deep .el-drawer {
  height: 80% !important;
}
::v-deep .el-drawer__header {
  text-align: center;
  color: #000;
}

.el-radio__original {
  display: none !important; /* 隐藏原生 radio 输入,但仍然允许交互 */
}

.el-table {
  height: 600px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
  .el-icon-circle-close {
    display: none;
  }
}
</style>
