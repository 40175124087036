<template>
  <div>
    <div> {{ placeholder }}</div>
    <div class="descBox">
    </div>
    <div class="btn">{{ buttonName }}</div>
  </div>
</template>
<script>
export default {
  props: ["buttonName", "placeholder"],
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  width: 50px;
  background-color: #0265ff;
  border-radius: 4px;
}
</style>