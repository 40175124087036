<template>
  <div>
    <quill-editor
      v-if="show"
      v-model="content"
      ref="editor"
      :options="options"
      disabled
      class="editor"
      style="height: 100%"
    ></quill-editor>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";

// 引入样式文件
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  data() {
    return {
      content: "",
      show: false,
      options: {
        placeholder: "",
      },
      toolbarTips: [
        { choice: ".ql-bold", title: "加粗" },

        { choice: ".ql-italic", title: "倾斜" },

        { choice: ".ql-underline", title: "下划线" },

        { choice: ".ql-header", title: "段落格式" },

        { choice: ".ql-strike", title: "删除线" },

        { choice: ".ql-blockquote", title: "块引用" },

        { choice: ".ql-code-block", title: "插入代码段" },

        { choice: ".ql-size", title: "字体大小" },

        { choice: '.ql-list[value="ordered"]', title: "编号列表" },

        { choice: '.ql-list[value="bullet"]', title: "项目列表" },

        { choice: '.ql-header[value="1"]', title: "h1" },

        { choice: '.ql-header[value="2"]', title: "h2" },

        { choice: ".ql-align", title: "对齐方式" },

        { choice: ".ql-color", title: "字体颜色" },

        { choice: ".ql-background", title: "背景颜色" },

        { choice: ".ql-image", title: "图像" },

        { choice: ".ql-video", title: "视频" },

        { choice: ".ql-link", title: "添加链接" },

        { choice: ".ql-formula", title: "插入公式" },

        { choice: ".ql-clean", title: "清除格式" },

        { choice: '.ql-indent[value="-1"]', title: "向左缩进" },

        { choice: '.ql-indent[value="+1"]', title: "向右缩进" },

        { choice: ".ql-header .ql-picker-label", title: "标题大小" },

        {
          choice: '.ql-header .ql-picker-item[data-value="1"]',
          title: "标题一",
        },

        {
          choice: '.ql-header .ql-picker-item[data-value="2"]',
          title: "标题二",
        },

        {
          choice: '.ql-header .ql-picker-item[data-value="3"]',
          title: "标题三",
        },

        {
          choice: '.ql-header .ql-picker-item[data-value="4"]',
          title: "标题四",
        },

        {
          choice: '.ql-header .ql-picker-item[data-value="5"]',
          title: "标题五",
        },

        {
          choice: '.ql-header .ql-picker-item[data-value="6"]',
          title: "标题六",
        },

        { choice: ".ql-header .ql-picker-item:last-child", title: "标准" },

        {
          choice: '.ql-size .ql-picker-item[data-value="small"]',
          title: "小号",
        },

        {
          choice: '.ql-size .ql-picker-item[data-value="large"]',
          title: "大号",
        },

        {
          choice: '.ql-size .ql-picker-item[data-value="huge"]',
          title: "超大号",
        },

        { choice: ".ql-size .ql-picker-item:nth-child(2)", title: "标准" },

        { choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },

        {
          choice: '.ql-align .ql-picker-item[data-value="center"]',
          title: "居中对齐",
        },

        {
          choice: '.ql-align .ql-picker-item[data-value="right"]',
          title: "居右对齐",
        },

        {
          choice: '.ql-align .ql-picker-item[data-value="justify"]',
          title: "两端对齐",
        },
      ],
    };
  },
  components: {
    quillEditor,
  },
  watch: {
    placeholder(newVal) {
      this.show = false;

      this.$set(this.options, "placeholder", this.placeholder);
      setTimeout(() => {
        this.show = true;
      }, 1);
    },
  },
  props: ["placeholder"],
  mounted() {
    this.$set(this.options, "placeholder", this.placeholder);
    this.$nextTick(() => {
      for (let item of this.toolbarTips) {
        let tip = document.querySelector(".editor " + item.choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    });
    this.show = true;
  },
};
</script>

