<template>
  <div>
    <el-drawer
      :visible="showDialog"
      :direction="'btt'"
      @close="close"
      size="90%"
      v-if="nodeDetai.data"
    >
      <div class="title">
        <div class="title_icon">
          <i class="iconfont icon-shuju5"></i>
        </div>
        <h4>{{ nodeDetai.data.form_name }}</h4>
        <div class="line"></div>
        <span>{{ nodeDetai.data.examine_name }}</span>
        <div class="line"></div>
        <div class="iconicon" v-if="flowRecord.length > 0">
          <i class="iconfont icon-shouliaodaiban"></i
          >{{
            flowRecord[flowRecord.length - 1].type === "end" ? "完结" : "进行中"
          }}
        </div>
      </div>
      <div style="display: flex">
        <div class="form">
          <formStart
            ref="formStart"
            tableRuleType="2"
            :formData="formData"
            :flowsFiled="
              nodeDetai.work_flow_info
                ? nodeDetai.work_flow_info.basicfieldPermission
                : []
            "
            v-if="show && showDialog"
            :formId="manageData.form_id"
            :appId="manageData.app_id"
            :oldPostData="datas.data.post_data"
            :formDataId="manageData.form_data_id"
            :oldEditData="oldEditData"
            :nodeControlsList="nodeControlsList"
            :onlyLook="true"
            :isFlowUpdate="true"
            :isDrat="false"
            :flowRecord="flowRecord"
            @editSuccess="isGeteWorkFlowsCheck"
          ></formStart>
          <div class="buttons" v-if="!onlyLook">
            <el-button
              size="small"
              @click="submutLC(4)"
              v-if="
                nodeDetai.work_flow_info.type == 'examine' &&
                nodeDetai.work_flow_info.nodeControls[5].value
              "
              >{{ nodeDetai.work_flow_info.nodeControls[5].lable }}</el-button
            >
            <el-button
              size="small"
              @click="submutLC(5)"
              v-if="
                nodeDetai.work_flow_info.type == 'examine' &&
                nodeDetai.work_flow_info.nodeControls[4].value
              "
              >{{ nodeDetai.work_flow_info.nodeControls[4].lable }}</el-button
            >
            <!-- <el-button
              size="small"
              @click="submutLC(6)"
              v-if="nodeDetai.work_flow_info.type == 'examine' && nodeDetai.work_flow_info.nodeControls[2].value"
              >暂存</el-button
            > -->
            <el-button
              size="small"
              @click="submutLC(7)"
              v-if="
                nodeDetai.work_flow_info.type == 'examine' &&
                nodeDetai.work_flow_info.nodeControls[3].value
              "
              >{{ nodeDetai.work_flow_info.nodeControls[3].lable }}</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="submutLC(3)"
              v-if="
                nodeDetai.work_flow_info.type == 'examine' &&
                nodeDetai.work_flow_info.nodeControls[1].value
              "
              >{{ nodeDetai.work_flow_info.nodeControls[1].lable }}</el-button
            >
            <el-button
              type="primary"
              size="small"
              style="width: 100px"
              @click="submutLC(2, 'print')"
              v-if="
                nodeDetai.work_flow_info.type == 'examine' &&
                nodeDetai.work_flow_info.nodeControls[6].value
              "
              >{{ nodeDetai.work_flow_info.nodeControls[6].lable }}</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="submutLC(2)"
              v-if="nodeDetai.work_flow_info.nodeControls[0].value"
              >{{ nodeDetai.work_flow_info.nodeControls[0].lable }}</el-button
            >
          </div>
        </div>
        <div class="flow">
          <div class="item" v-for="(item, index) in flowRecord" :key="index">
            <div class="dot_header">
              <div class="dot">
                <div class="dot_son"></div>
              </div>
              <span>{{ item.examine_name }}</span>
            </div>
            <div class="flow_line"></div>
            <div class="main">
              <div
                class="main_header"
                style="display: flex; justify-content: space-between"
              >
                <div>
                  <div
                    v-for="(name, index2) in userNameArray(item.user_name)"
                    :key="index2"
                  >
                    <i class="tou">{{ name[0] }}</i>
                    <span>{{ name }}</span>
                  </div>
                </div>
                <span class="state" v-if="item.status === 2">{{
                  nodeDetai.work_flow_info.nodeControls[0].lable
                }}</span>
                <span
                  class="state blue"
                  v-else-if="item.status === 1 && item.type == 'start'"
                  >待提交</span
                >
                <span class="state blue" v-else-if="item.status === 1"
                  >待审核</span
                >
                <span class="state yellow" v-else-if="item.status === 4"
                  >加签</span
                >
                <span class="state blue" v-else-if="item.status === 5"
                  >转交</span
                >
                <span class="state pink" v-else-if="item.status === 6"
                  >暂存</span
                >
                <span class="state red" v-else-if="item.status === 7"
                  >回退</span
                >
                <span class="state red" v-else-if="item.status === 3"
                  >驳回</span
                >
                <span class="state red" v-else-if="item.status === 8"
                  >撤回</span
                >
              </div>

              <div class="main_item">
                开始时间：<span>{{ item.add_time }}</span>
              </div>
              <div class="main_item" v-if="item.end_time && index !== 0">
                结束时间：<span>{{ item.end_time }}</span>
              </div>
              <div
                class="main_item"
                v-if="
                  item.end_time &&
                  (item.type == 'examine' ||
                    (item.type == 'start' && item.status === 1))
                "
              >
                耗时：<span>{{
                  getFormattedTimeDiff(item.add_time, item.end_time)
                }}</span>
              </div>
              <div class="main_item" v-if="item.type == 'examine'">
                处理意见：{{ item.opinion }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="加签"
      :visible.sync="dialogCountersign"
      width="25%"
      :close-on-click-modal="false"
      @close="countersignClose"
      class="is-top-dialog"
    >
      <div class="mianCountersign">
        <h4>加签方式</h4>
        <div class="lineJia"></div>
        <div class="radioJia">
          <!-- <el-checkbox v-model="checked1">前加签</el-checkbox>
          <el-checkbox v-model="checked2">后加签</el-checkbox> -->
          <el-radio v-model="radio" label="1"
            >前加签<span class="text"
              >被加签人先处理，之后我处理</span
            ></el-radio
          >
          <el-radio v-model="radio" label="2"
            >后加签<span class="text"
              >我已同意提交，之后让被加签人处理</span
            ></el-radio
          >
        </div>
        <h4>选择审批人</h4>
        <div class="lineJia"></div>
        <div class="select" @click="selectPrincipalTmp">点击选择负责人</div>
      </div>

      <!-- <el-dialog
        width="30%"
        title="添加人员"
        :visible.sync="innerVisible"
        append-to-body
      >
        <div class="selectPrincipal">
          <div class="showTab"></div>
        </div>
        <div class="changetable">
          <div class="changetable_tab">成员</div>
        </div>
        <div class="content">
          <div class="content_left">
            <el-tree
              ref="tree"
              :data="departData"
              node-key="id"
              :props="defaultProps"
              :check-on-click-node="false"
              @node-click="nodeDepartClick"
            >
              <span slot-scope="{ node, data }">
                <i
                  style="color: #409dff; margin-right: 10px"
                  class="iconfont icon-zuzhibumen"
                ></i>
                <span>{{ data.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="content_right">
            <div class="found_2_heade">
              <div>已选择：{{ checkedList.length }}人</div>
              <div>
                全部
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll_cy"
                  @change="handleCheckAllChange"
                ></el-checkbox>
              </div>
            </div>
            <ul>
              <el-checkbox-group
                v-model="checkedList"
                style="height: 100%; font-size: 14px"
              >
                <li v-for="item in nameArr" :key="item._id">
                  <div>
                    <i class="head_portrait">{{ item.name.charAt(0) }}</i>
                    <span style="margin-right: 10px">{{ item.name }}</span>
                  </div>
                  <el-checkbox :label="item._id"></el-checkbox>
                </li>
              </el-checkbox-group>
            </ul>
          </div>
        </div>
      </el-dialog> -->

      <!-- <menberSelect
        :dialogVisible="dialogVisible"
        @close="selectMenberClose"
        @submit="selectMenberSubmit"
        :data="datas"
        :type="type"
        :oldSelectMenberList="oldSelectMenberList"
        :oldAllCheck="oldAllCheck"
      ></menberSelect> -->
      <addMembers
        :dialogVisible="dialogVisible"
        :oldSelectMenberList="oldSelectMenberList"
        :isoldMenberList="true"
        @handleClose="selectMenberClose"
        @submit="selectMenberSubmit"
      ></addMembers>

      <span slot="footer" class="dialog-footer">
        <el-button @click="countersignClose">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="处理意见"
      :visible.sync="dialogOpinion"
      width="30%"
      :close-on-click-modal="false"
      @close="dialogClose"
      class="is-top-dialog"
    >
      <div>
        <el-input type="textarea" :rows="3" v-model="dialogText"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button
          type="primary"
          @click="geteWorkFlowsCheck"
          v-loading.fullscreen.lock="loading"
          element-loading-text="提交中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <addMembers
      v-if="menberDialog"
      :dialogVisible="menberDialog"
      @handleClose="handleClose"
      @submit="submit"
      type="admin"
      :isall="true"
      :loadings="loading"
    ></addMembers>
  </div>
</template>

<script>
import moment from "moment";
import {
  geteWorkFlowsRecord,
  geteWorkFlowsDetail,
  getAppFormView,
  getAppFormDataDetail,
  geteWorkFlowsFiled,
  geteWorkFlowsCheck,
  postOverruled,
  postRollback,
  postBackoff,
  postAddsign,
} from "@/api/app";
import formStart from "@/components/firmStart/formSubmit.vue";

import menberSelect from "../menberSelect/index.vue";
import addMembers from "@/components/addMembers/index";

export default {
  data() {
    return {
      dialogCountersign: false,
      dialogVisible: false,
      isIndeterminate: false,
      dialogOpinion: false,
      dialogType: 2,
      dialogText: "",
      checkAll_cy: false,
      radio: "1",
      defaultProps: {
        children: "children",
        label: "label",
      },
      departData: [],
      checkedList: [],
      nameArr: [],

      //记录列表
      flowRecord: [],
      //流程权限
      formData: {},
      show: false,
      //节点详情
      nodeDetai: {},

      datas: {},
      type: "custom",
      oldSelectMenberList: [],
      oldEditData: {},
      //添加成员
      menberDialog: false,
      rollbackOpinion: "",
      isSubmit: true,
      loading: false,
      nodeControlsList: [],
      fullscreenLoading: false,
      status: "",
    };
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    manageData: {},
    onlyLook: false,
  },

  components: {
    formStart,
    menberSelect,
    addMembers,
  },
  computed: {},
  mounted() {},
  watch: {
    checkedList(val) {
      if (val.length === this.nameArr.length && this.nameArr.length != 0) {
        this.checkAll_cy = true;
      } else {
        this.checkAll_cy = false;
      }
      this.checkedArr_cy = [];
      val.forEach((item) => {
        this.nameArr.forEach((itemA) => {
          if (item === itemA._id) {
            this.checkedArr_cy.push(itemA);
          }
        });
      });
    },
    showDialog(val) {
      if (val) {
        this.geteWorkFlowsRecord();
        this.getList();
      }
    },
  },
  methods: {
    getFormattedTimeDiff(addTime, sendTime) {
      addTime = moment(addTime, "YYYY-MM-DD HH:mm:ss").unix();
      sendTime = moment(sendTime, "YYYY-MM-DD HH:mm:ss").unix();
      var startTimestamp = addTime; // 开始时间戳，单位为秒
      var endTimestamp = sendTime; // 结束时间戳，单位为秒
      var startMoment = moment.unix(startTimestamp);
      var endMoment = moment.unix(endTimestamp);

      var duration = moment.duration(endMoment.diff(startMoment));
      var diffDays = Math.floor(duration.asDays());
      var diffHours = duration.hours();
      var diffMinutes = duration.minutes();
      var diffSeconds = duration.seconds();

      var formattedDiff = "";
      if (diffDays > 0) {
        formattedDiff += diffDays + " 天 ";
      }
      if (diffHours > 0) {
        formattedDiff += diffHours + " 小时 ";
      }
      if (diffMinutes > 0) {
        formattedDiff += diffMinutes + " 分钟 ";
      }
      if (diffSeconds > 0) {
        formattedDiff += diffSeconds + " 秒";
      }
      return formattedDiff;
    },
    userNameArray(val) {
      // 将item.user_name转换为数组
      return val.split(",");
    },
    geteWorkFlowsRecord() {
      geteWorkFlowsRecord({
        step_id: this.manageData.step_id,
        form_id: this.manageData.form_id,
        form_data_id: this.manageData.form_data_id,
      }).then((res) => {
        if (res.code == 200) {
          this.flowRecord = res.data;
        }
      });
    },
    getList() {
      console.log("111");
      this.formData = {};
      geteWorkFlowsDetail({
        step_id: this.manageData.step_id,
        form_id: this.manageData.form_id,
        _id: this.manageData._id,
      }).then((res) => {
        console.log("res", res);

        if (res.code == 200) {
          this.nodeDetai = res.data;
          console.log("哈哈哈", this.nodeDetai);
          this.nodeControlsList = res.data.work_flow_info.nodeControls;
        }
      });

      getAppFormDataDetail({
        app_id: this.manageData.app_id,
        form_id: this.manageData.form_id,
        id: this.manageData.form_data_id,
      }).then((res) => {
        if (res.code == 200) {
          this.datas = res.data;
          let datas2 = res.data.data;
          for (let i in datas2) {
            if (i.slice(0, 2) == "vw") {
              this.oldEditData[i] = datas2[i];
            }
          }
          this.getFormData(res.data.data);
        }
      });
    },
    getFormData(datas) {
      getAppFormView(this.manageData.app_id, this.manageData.form_id).then(
        (res) => {
          console.log("回显数据", res);

          if (res.data.content) {
            this.formData = JSON.parse(res.data.content)[0];
            console.log(this.formData, 444444);
            // this.formData.fields.forEach((item) => {
            //   for (let i in datas) {
            //     if (
            //       item.__config__.tagIcon == "row" &&
            //       item.__config__.componentName === i
            //     ) {
            //       item.__config__.children.forEach((itemA) => {
            //         for (let j in datas[i][0]) {
            //           if (itemA.__vModel__ === j) {
            //             itemA.__config__.defaultValue = datas[i][0][j][j];
            //           }
            //         }
            //       });
            //     } else if (item.__vModel__ === i) {
            //       item.__config__.defaultValue = datas[i];
            //     }
            //   }
            // });
            // console.log(this.formData, 999);
            this.show = true;
          } else {
            this.show = false;
          }
        }
      );
    },
    close() {
      this.$emit("update:showDialog", false);
    },
    countersign() {
      this.dialogCountersign = true;
    },
    countersignClose() {
      this.dialogCountersign = false;
    },
    selectPrincipalTmp() {
      this.dialogVisible = true;
    },
    handleCheckChange() {},
    treeDataChange(data) {
      data.forEach((item) => {
        this.$set(item, "id", item._id);
        this.$set(item, "label", item.name);
        delete item._id;
        delete item.name;
        if (item.children) {
          this.treeDataChange(item.children);
        }
      });
      return data;
    },

    submutLC(type, status) {
      // if(!this.isSubmit){
      // 	return
      // }
      // setTimeout(() => {
      // 	that.isSubmit = true;
      // }, 1500);
      // this.loading = false
      this.status = status;
      this.dialogType = type;
      if (this.nodeDetai.work_flow_info.ideaSwitch) {
        this.dialogOpinion = true;
      } else {
        if (this.dialogType == 2) {
          this.$refs.formStart.submitForm(status);
        } else {
          if (this.dialogType == 3) {
            this.isPostOverruled();
          } else if (this.dialogType == 7) {
            this.isPostRollback();
          } else if (this.dialogType == 5 || this.dialogType == 4) {
            this.menberDialog = true;
          } else {
            this.isGeteWorkFlowsCheck();
          }
        }
      }
      // this.loading = true
    },

    //流程审批
    geteWorkFlowsCheck(type) {
      // console.log('geteWorkFlowsCheck');
      if (this.dialogText == "") {
        this.$message({
          message: "请填写处理意见",
          type: "warning",
        });
        return;
      }
      // if(!this.isSubmit){
      // 	return
      // }
      // setTimeout(() => {
      // 	that.isSubmit = true;
      // }, 1500);

      if (this.dialogType == 2) {
        this.$refs.formStart.submitForm(this.status);
        //this.dialogOpinion = false;
      } else {
        if (this.dialogType == 3) {
          this.isPostOverruled();
        } else if (this.dialogType == 7) {
          this.isPostRollback();
        } else if (this.dialogType == 5 || this.dialogType == 4) {
          this.rollbackOpinion = this.dialogText;
          this.dialogOpinion = false;
          this.menberDialog = true;
        } else {
          this.loading = true;
          this.isGeteWorkFlowsCheck();
        }
      }
    },
    // isGeteWorkFlowsCheck() {
    //   // console.log('1111111111');
    //   // this.loading = true
    //   this.fullscreenLoading = true;
    //   geteWorkFlowsCheck({
    //     id: this.manageData._id,
    //     form_id: this.manageData.form_id,
    //     status: this.dialogType,
    //     opinion: this.dialogText,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.dialogOpinion = false;
    //       this.close();
    //       this.$message.success(res.msg);
    //       this.$emit("updateList", false);
    //     }
    //   });
    //   // this.loading = false
    //   setTimeout(() => {
    //     this.fullscreenLoading = false;
    //   }, 2000);
    // },

    async isGeteWorkFlowsCheck(type) {
      this.loading = true;
      try {
        const res = await geteWorkFlowsCheck({
          id: this.manageData._id,
          form_id: this.manageData.form_id,
          status: this.dialogType,
          opinion: this.dialogText,
        });
        if (res.code == 200) {
          this.dialogOpinion = false;
          if (type !== "print") {
            this.close();
          }

          this.$message.success(res.msg);
          this.$emit("updateList", false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      // this.fullscreenLoading = true;

      // geteWorkFlowsCheck({
      //   id: this.manageData._id,
      //   form_id: this.manageData.form_id,
      //   status: this.dialogType,
      //   opinion: this.dialogText,
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.dialogOpinion = false;
      //     this.close();
      //     this.$message.success(res.msg);
      //     this.$emit("updateList", false);
      //   }
      // });
      // this.loading = false
    },

    isPostOverruled() {
      // console.log('333333333');
      this.loading = true;
      postOverruled({
        form_data_id: this.manageData.form_data_id,
        form_id: this.manageData.form_id,
        opinion: this.dialogText,
      }).then((res) => {
        if (res.code == 200) {
          this.dialogOpinion = false;
          this.close();
          this.$message.success(res.msg);
          this.$emit("updateList", false);
          this.loading = false;
        }
      });
    },
    isPostRollback() {
      // console.log('4444444444');
      this.loading = true;
      // this.fullscreenLoading = true;
      postRollback({
        form_data_id: this.manageData.form_data_id,
        form_id: this.manageData.form_id,
        opinion: this.dialogText,
      }).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.dialogOpinion = false;
          this.close();
          this.$message.success(res.msg);
          this.$emit("updateList", false);
        }
      });
    },

    // isPostBackoff(user_id) {
    //   console.log('0000000000');
    //   this.loading = true
    //   postBackoff({
    //     form_data_id: this.manageData.form_data_id,
    //     form_id: this.manageData.form_id,
    //     opinion: this.rollbackOpinion,
    //     user_id: user_id,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.loading = false
    //       this.dialogOpinion = false;
    //       this.close();
    //       this.$message.success(res.msg);
    //       this.$emit("updateList", false);
    //     }
    //   });

    // },
    async isPostBackoff(user_id) {
      // console.log('0000000000');
      this.loading = true;
      try {
        const res = await postBackoff({
          form_data_id: this.manageData.form_data_id,
          form_id: this.manageData.form_id,
          opinion: this.rollbackOpinion,
          user_id: user_id,
        });
        if (res.code == 200) {
          // this.loading = false
          this.dialogOpinion = false;
          this.close();
          this.$message.success(res.msg);
          this.handleClose();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      // this.fullscreenLoading = true;
      // const res = await postBackoff({
      //   form_data_id: this.manageData.form_data_id,
      //   form_id: this.manageData.form_id,
      //   opinion: this.rollbackOpinion,
      //   user_id: user_id,
      // });
      // this.loading = true
      // postBackoff({
      //   form_data_id: this.manageData.form_data_id,
      //   form_id: this.manageData.form_id,
      //   opinion: this.rollbackOpinion,
      //   user_id: user_id,
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.loading = false
      //     this.dialogOpinion = false;
      //     this.close();
      //     this.$message.success(res.msg);
      //     this.$emit("updateList", false);
      //   }
      // });
    },

    // isPostAddsign(user_id) {
    //   console.log('mmmmmmmmmmmm');
    //   this.loading = true
    //   postAddsign({
    //     form_data_id: this.manageData.form_data_id,
    //     form_id: this.manageData.form_id,
    //     opinion: this.rollbackOpinion,
    //     user_id: user_id,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success(res.msg);
    //       this.geteWorkFlowsRecord();
    //       this.loading = false
    //     }
    //   });
    // },

    async isPostAddsign(user_id) {
      // console.log('mmmmmmmmmmmm');
      this.loading = true;
      try {
        const res = await postAddsign({
          form_data_id: this.manageData.form_data_id,
          form_id: this.manageData.form_id,
          opinion: this.rollbackOpinion,
          user_id: user_id,
        });
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.geteWorkFlowsRecord();
          this.dialogOpinion = false;
          this.close();
          this.handleClose();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    dialogClose() {
      this.dialogOpinion = false;
      this.dialogText = "";
    },

    selectMenberClose() {
      this.dialogVisible = false;
    },
    selectMenberSubmit(data, allCheck) {
      this.oldSelectMenberList = data;
      this.selectMenberClose();
      console.log("提交", data, allCheck);
    },
    //关闭转交弹窗
    handleClose() {
      this.menberDialog = false;
    },
    submit(id, name) {
      // if(!this.isSubmit){
      // 	return
      // }
      // setTimeout(() => {
      // 	that.isSubmit = true;
      // }, 1500);
      // this.loading = false
      if (this.dialogType == 5) {
        this.isPostBackoff(id[0]);
      } else {
        this.isPostAddsign(id[0]);
      }
      // this.handleClose();
      // this.loading = true
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-message {
  z-index: 99999 !important;
}

::v-deep .el-message-error {
  z-index: 99999 !important;
}

// ::v-deep .el-drawer__header {
//   padding: 0;
// }
// ::v-deep .el-tree > .el-tree-node {
//   border-bottom: unset !important;
//   padding-left: unset !important;
//   padding-right: 14px;
//   box-sizing: border-box;
//   position: relative;
// }
// ::v-deep .el-tree-node__content {
//   position: relative;
// }
// ::v-deep .el-checkbox {
//   position: absolute;
//   right: 0;
//   top: 9px;
// }
.buttons {
  text-align: right;
  width: 75%;
  height: 50px;
  line-height: 50px;
  padding-right: 26px;

  .el-button {
    width: 66px;
  }
}

.title {
  display: flex;
  padding: 0px 24px 12px;
  border-bottom: 1px solid #e4e7ed;
  margin-bottom: 30px;

  .title_icon {
    width: 24px;
    height: 24px;
    background: #4fd6b8;
    border-radius: 4px;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    margin-left: 10px;

    i {
      color: #fff;
    }
  }

  h4 {
    margin: 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #303133;
    line-height: 28px;
  }

  .line {
    width: 1px;
    margin-top: 6px;
    height: 15px;
    margin: 0 8px;
    margin-top: 6px;
    background-color: #ccc;
  }

  span {
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #535866;
    line-height: 28px;
  }

  .iconicon {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #49c48c;
    line-height: 28px;

    i {
      margin-right: 10px;
    }
  }
}

.form {
  width: 75%;
  margin-right: 25px;
  box-sizing: border-box;
  padding: 0 24px;
  box-shadow: 3px 0px 5px rgb(0 0 0 / 10%);

  .formSubmit {
    height: auto;
    min-height: 90%;
  }

  // .formItem {
  //   height: 700px;
  //   overflow-y: scroll;
  //   padding-right: 10px;
  // }
  // ::v-deep .el-form {
  //   width: 100%;
  //   .el-input__inner {
  //     border-radius: 0;
  //     height: 36px;
  //   }
  //   .el-textarea__inner {
  //     height: 145px;
  //     border-radius: 0;
  //   }
  //   .el-select {
  //     width: 100%;
  //   }
  // }
}

.flow {
  width: 25%;
  height: 700px;
  overflow-y: scroll;
  padding-right: 5px;

  .item {
    position: relative;

    .dot {
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #409eff;
      margin-right: 8px;

      .dot_son {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #409eff;
        margin-left: 3px;
        margin-top: 3px;
      }
    }

    .dot_header {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #303133;
        line-height: 22px;
        padding: 8px 0;
      }
    }

    .flow_line {
      position: absolute;
      top: 20px;
      left: 9px;
      width: 1px;
      height: 100%;
      background-color: #409eff;
    }

    .main {
      width: 93%;
      // height: 140px;
      margin-left: 25px;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 16px 24px;
      box-shadow: 0px 0px 8px 0px #ccc;
      border-radius: 4px;

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #303133;
        line-height: 22px;
      }

      .tou {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: #409eff;
        border-radius: 50%;
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        font-style: normal;
        text-align: center;
      }

      .state {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #49c48c;
        line-height: 28px;
      }

      .state.yellow {
        color: #ffa42f;
      }

      .state.blue {
        color: #409dff;
      }

      .state.pink {
        color: #ff5cfd;
      }

      .state.red {
        color: #ff5f5f;
      }

      .main_item {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #303133;
        line-height: 22px;
        padding-bottom: 5px;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #303133;
        }
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding: 17px 24px;
}

.mianCountersign {
  h4 {
    margin: 0;
    margin-bottom: 12px;
  }

  .lineJia {
    width: 100%;
    height: 1px;
    background: #ccc;
  }

  .radioJia {
    margin-bottom: 25px;

    .el-radio {
      display: block;
      margin-top: 16px;

      ::v-deep .el-radio__label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #303133;
      }

      .text {
        margin-left: 10px;
        font-size: 12px;
        color: #676e80;
      }
    }
  }

  .select {
    cursor: pointer;
    height: 43px;
    text-align: center;
    line-height: 43px;
    background: #f5f8fc;
    border-radius: 4px;
  }
}

.selectPrincipal {
  .showTab {
    height: 70px;
    background: #ffffff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    margin-bottom: 24px;
  }
}

.changetable {
  .changetable_tab {
    width: 30px;
    color: #409eff;
    border-bottom: 2px solid #409eff;
  }
}

.content {
  display: flex;
  height: 300px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 12px;
  overflow-y: scroll;

  .content_left {
    width: 50%;
    height: 100%;
    border-right: 1px solid #ccc;
  }

  .content_right {
    width: 50%;
    height: 100%;

    .found_2_heade {
      display: flex;
      align-items: center;
      line-height: 32px;
      justify-content: space-between;
      padding: 16px 24px;
      box-sizing: border-box;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        margin: 0;
        margin-bottom: 15px;

        ::v-deep .el-checkbox__label {
          display: none;
        }
      }
    }

    .head_portrait {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background: #409eff;
      border-radius: 50%;
      display: inline-block;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      font-style: normal;
      text-align: center;
    }

    .el-checkbox {
      all: initial;
    }
  }
}

.is-top-dialog,
::v-deep .el-dialog__wrapper {
  z-index: 3000 !important;
}
</style>
