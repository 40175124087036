<template>
  <section class="app-main">
    <transition name="el-fade-in-linear" mode="out-in">
      <router-view />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {},
};
</script>

<style>
.app-main {
  /* 50= navbar  50  */
  /* height: calc(100% - 54px); */
  height: 100%;
  width: 100%;

  background-color: #f5f8fc;
}
</style>
