<template>
  <div class="perspective-warp" v-if="show">
    <div class="table">
      <div class="table-header">
        <div class="table-header-left">
          <div
            class="left-item table-header-cell"
            v-for="(item, index) in data.yFields"
            :key="index"
          >
            {{ item.title }}
          </div>
          <div class="table-header-x">
            <div
              class="header-x-item table-header-cell"
              v-for="(item, index) in data.xFields"
              :key="index"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="table-header-x-ul">
            <div
              class="table-header-x-li"
              v-for="(item, index) in data.data.x"
              :key="index"
              :style="{
                borderRight:
                  index < data.data.x.length - 1 ? '1px solid #eee' : '',
              }"
            >
              <div v-if="index === 0">
                <div
                  v-for="(itemA, indexA) in dataXList"
                  :key="indexA"
                  class="table-header-x-li-item"
                  :style="{ height: itemA.count * 32 + 'px' }"
                >
                  {{ itemA.value }}
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(itemA, indexA) in item.data"
                  :key="indexA"
                  class="table-header-x-li-item"
                >
                  {{ itemA }}
                </div>
              </div>
            </div>
          </div>
          <div class="table-header-cell" style="background-color: #fff">
            汇总
          </div>
        </div>

        <div class="table-header-right">
          <perspectiveTree :treeData="treeData"></perspectiveTree>
        </div>
        <div class="table-header-count">
          <div
            class="table-header-cell"
            :style="{ height: 32 * data.yFields.length + 'px' }"
          >
            汇总
          </div>
          <div class="table-header-count-title">
            <div v-for="(item, index) in lineCountList" :key="index">
              <div class="table-header-cell">{{ item.text }}</div>
              <div
                class="table-header-cell"
                style="background-color: #fff"
                v-for="(itemA, indexA) in item.data"
                :key="indexA"
              >
                {{ itemA ? itemA : "-" }}
              </div>
              <div class="table-header-cell" style="background-color: #fff">
                {{ item.sum }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import perspectiveTree from "./perspectiveTree";
export default {
  data() {
    return {
      show: false,
      data: {},
      guideline: [],
      guideline_val: {},
      drilldown: "pc",
      dataXList: [],
      lineCountList: [],
    };
  },

  mounted() {},
  props: ["option", "chartData", "colorList", "defaultColor", "chartId"],
  components: { perspectiveTree },
  watch: {
    chartData: {
      handler(val) {
        console.log(val, "哈哈哈哈哈");
        this.data = val;
        this.treeData = this.mergeData(val.data.val);

        this.setData();
        this.show = true;
      },
      deep: true,
    },
  },
  methods: {
    mergeData(data) {
      const tree = [];

      data.forEach((item) => {
        const { y, text, sum, data } = item;

        // Navigate through the 'y' hierarchy to find/create nodes
        let currentLevel = tree;

        y.forEach((key, index) => {
          // const keyStr = String(key); // Convert to string for consistent matching
          let keyStr = "";
          if (typeof key === "object") {
            keyStr = key.year + "年" + key.month + "月";
            if (key.day) {
              keyStr = keyStr + key.day + "日";
            }
          } else {
            keyStr = key;
          }
          let existingNode = currentLevel.find((node) => node.name === keyStr);

          // If the node does not exist, create it
          if (!existingNode) {
            existingNode = { name: keyStr, children: [] };
            currentLevel.push(existingNode);
          }

          // Move to the next level of the tree
          currentLevel = existingNode.children;

          // If it's the last item in y, we add the value
          if (index === y.length - 1) {
            existingNode.children.push({
              name: text,
              sum: sum,
              data: data,
            });
          }
        });
      });

      return tree;
    },
    setData() {
      this.data.data.x.forEach((item) => {
        item.data.forEach((itemA, indexA) => {
          if (typeof itemA === "object") {
            if (itemA.day) {
              this.$set(
                item.data,
                indexA,
                itemA.year + "/" + itemA.month + "/" + itemA.day
              );
            } else {
              this.$set(
                item.data,
                indexA,
                (itemA = itemA.year + "/" + itemA.month + "月")
              );
            }
          }
        });
      });
      let arr = [];
      let xList = this.data.data.x[0].data;
      if (typeof xList[0] === "object") {
        if (xList[0].day) {
          xList.forEach((item) => {
            arr.push(item.year + "/" + item.month + "/" + item.day);
          });
        } else {
          xList.forEach((item) => {
            arr.push(item.year + "/" + item.month + "月");
          });
        }
      } else {
        arr = xList;
      }
      const result = arr.reduce((acc, value) => {
        const existing = acc.find((item) => item.value === value);
        if (existing) {
          existing.count += 1;
        } else {
          acc.push({ value, count: 1 });
        }
        return acc;
      }, []);

      this.dataXList = result;
      this.lineCountList = [];
      this.data.data.val.forEach((item) => {
        if (item.y.length === 0) {
          this.lineCountList.push(item);
        }
      });
    },
  },
  created() {},
  mounted() {
    // this.treeData = this.mergeData(this.data.data.val);
    // this.setData();
  },
};
</script>

<style lang="scss" scoped>
.perspective-warp {
  position: relative;
  height: 100%;
  width: 100%;
}
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.table {
  // min-width: 100%;
  // overflow-x: auto;
  // height: 100%;
}
.table-header {
  min-width: 100%;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  // border-right: 1px solid #eee;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.table-header-cell {
  background: #f5f6f8;
  color: #000;
  font-size: 13px;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  text-align: left;
  border-bottom: 1px solid #eee;
  min-width: 100px;
  box-sizing: border-box;
}
.table-header-left {
  border-right: 1px solid #eee;
}
.table-header-x {
  display: flex;
  align-items: center;
}
.header-x-item {
  border-right: 1px solid #eee;
}
.header-x-item:last-child {
  border-right: unset;
}
.table-header-x-ul {
  display: flex;
  align-items: center;
}
.table-header-x-li-item {
  color: #000;
  font-size: 13px;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  text-align: left;

  border-bottom: 1px solid #eee;
  min-width: 100px;
  box-sizing: border-box;
}
.table-header-count {
  border-right: 1px solid #eee;
}
.table-header-count-title {
  display: flex;
  align-items: center;
  & > div:nth-child(1) {
    border-right: 1px solid #eee;
  }
  & > div:last-child {
    border-right: unset;
  }
}
</style>
