<template>
  <div class="radar-warp">
    <div class="radar-count" v-if="type !== '4'" style="height: 100%">
      <div
        :id="id"
        :style="{
          height: '100%',
          background:
            option.cssStyle.bgType === '1'
              ? option.cssStyle.bgColor
              : `url(${option.cssStyle.bgUrl}) `,
        }"
      ></div>
    </div>

    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",

      chartType: "1",
      id: "radar",
    };
  },
  components: {},
  mounted() {},
  props: ["option", "chartData", "defaultColor", "colorList", "chartId"],

  watch: {
    option: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    colorList: {
      handler(val) {},
      deep: true,
    },
    type(val) {},
  },
  methods: {
    getList(data) {
      let len = data.draggableObj.dimensionList.length;
      let len2 = data.draggableObj.targatList.length;
      if (data.collapseActive) {
        if (data.collapseActive.title === "线条") {
          this.chartType = "1";
        } else if (data.collapseActive.title === "填充") {
          this.chartType = "2";
        }
      }
      if (len === 1 && len2 > 1) {
        this.type = "1";
        this.$nextTick(() => {
          this.initChart(data, this.chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 2 && len2 === 1) {
        this.type = "2";
        this.$nextTick(() => {
          this.initChart2(data, this.chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 1 && len2 === 1) {
        this.type = "1";
        this.$nextTick(() => {
          this.initChart(data, this.chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else {
        this.type = "4";
      }
    },
    initChart(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var option;
      if (myChart) {
        myChart.clear();
      }
      let legendList = [];
      data.draggableObj.targatList.forEach((item) => {
        legendList.push(item.__config__.label);
      });

      let colorArr = [];

      this.colorList.forEach((item) => {
        colorArr.push(item.color);
      });
      let indicatorList = [];
      chartData[0].xAxis.forEach((item) => {
        indicatorList.push({
          name: item,
          max: data.yAxis.max ? data.yAxis.max : "dataMax",
          min: data.yAxis.min ? data.yAxis.min : 0,
        });
      });
      let dataList = [];
      chartData.forEach((item) => {
        dataList.push({
          value: item.yAxis,
          name: item.zb_name,
        });
      });
      option = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            let result = `${params.name}<br>`;
            chartData[params.dataIndex].xAxis.forEach((item, index) => {
              if (chartData[params.dataIndex].advanced === "proportion") {
                let sumArray = chartData[params.dataIndex].yAxis.reduce(
                  (sum, num) => sum + num,
                  0
                );
                let count =
                  ((params.value[index] / sumArray) * 100).toFixed(2) + "%";
                result += `${params.marker} ${item} : ${count}</br>`;
              } else {
                result += `${params.marker} ${item} : ${params.value[index]}</br>`;
              }
            });
            return result;
          },
        },

        legend: {
          data: legendList,
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },
        radar: {
          // shape: 'circle',
          center: ["50%", "55%"],
          indicator: indicatorList,
        },
        series: [
          {
            type: "radar",
            color: colorArr.length === 0 ? this.defaultColor : colorArr,
            areaStyle: this.chartType === "1" ? null : {},
            data: dataList,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  if (chartData[params.dataIndex].advanced === "proportion") {
                    let sumArray = chartData[params.dataIndex].yAxis.reduce(
                      (sum, num) => sum + num,
                      0
                    );
                    let count =
                      ((params.value / sumArray) * 100).toFixed(2) + "%";
                    return count;
                  } else {
                    return params.value;
                  }
                },
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    initChart2(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var option;
      if (myChart) {
        myChart.clear();
      }
      let legendList = [];
      data.draggableObj.targatList.forEach((item) => {
        legendList.push(item.__config__.label);
      });

      let colorArr = [];

      this.colorList.forEach((item) => {
        colorArr.push(item.color);
      });
      let indicatorList = [];
      let xAxisList = [];
      chartData[0].xAxis.forEach((item, index) => {
        if (index > 0) {
          indicatorList.push({
            name: item,
            max: data.yAxis.max ? data.yAxis.max : "dataMax",
            min: data.yAxis.min ? data.yAxis.min : 0,
          });
          xAxisList.push(item[0]);
        }
      });
      let dataList = [];

      chartData[0].yAxis.forEach((item) => {
        let arr = [];
        item.forEach((itemA, indexA) => {
          if (indexA > 0) {
            arr.push(itemA);
          }
        });
        dataList.push({
          value: arr,
          name: item[0],
        });
      });

      option = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            let result = `${params.name}<br>`;
            xAxisList.forEach((item, index) => {
              if (chartData[0].advanced === "proportion") {
                let sumArray = dataList[params.dataIndex].value.reduce(
                  (sum, num) => sum + num,
                  0
                );
                let count =
                  ((params.value[index] / sumArray) * 100).toFixed(2) + "%";
                result += `${params.marker} ${item} : ${count}</br>`;
              } else {
                result += `${params.marker} ${item} : ${params.value[index]}</br>`;
              }
            });
            return result;
          },
        },

        legend: {
          data: legendList,
        },
        radar: {
          // shape: 'circle',
          indicator: indicatorList,
        },
        series: [
          {
            type: "radar",
            color: colorArr.length === 0 ? this.defaultColor : colorArr,
            areaStyle: this.chartType === "1" ? null : {},
            data: dataList,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  console.log(params);
                  if (chartData[0].advanced === "proportion") {
                    let sumArray = dataList[params.dataIndex].value.reduce(
                      (sum, num) => sum + num,
                      0
                    );
                    let count =
                      ((params.value / sumArray) * 100).toFixed(2) + "%";
                    return count;
                  } else {
                    return params.value;
                  }
                },
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
  created() {},
  mounted() {
    if (this.chartId) {
      this.id = this.chartId;
    }
    this.getList(this.option, this.chartData);
  },
};
</script>

<style lang="scss" scoped>
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.radar-warp {
  position: relative;
  height: 100%;
  width: 100%;

  #radar {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-attachment: fixed;
  }
}
</style>
