<!--  -->
<template>
  <div ref="datePicker">
    <div v-if="type === 'time'">
      <el-time-picker
        v-model="value"
        placeholder="请选择时间"
        :value-format="format"
        :format="format"
        @change="sendValue"
        :disabled="data.disabled"
      >
      </el-time-picker>
    </div>
    <div v-else-if="type === 'timerang'">
      <el-time-picker
        v-model="value"
        placeholder="选择时间范围"
        is-range
        @change="sendValue"
        :value-format="format"
        :format="format"
        range-separator="至"
        :disabled="data.disabled"
      >
      </el-time-picker>
    </div>
    <div v-else-if="type === 'date'">
      <el-date-picker
        v-model="value"
        placeholder="选择日期"
        @change="sendValue"
        :type="
          format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'date'
        "
        :value-format="format"
        :format="format"
        :disabled="data.disabled"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div v-else-if="type === 'daterang'">
      <el-date-picker
        v-model="value"
        placeholder="选择日期"
        @change="sendValue"
        type="daterange"
        :value-format="format"
        :format="format"
        :disabled="data.disabled"
      >
      </el-date-picker>
    </div>
    <div v-else-if="type === 'datetimerang'">
      <el-date-picker
        v-model="value"
        type="datetimerange"
        @change="sendValue"
        :value-format="format"
        :format="format"
        :disabled="data.disabled"
      >
      </el-date-picker>
    </div>
    <div v-else-if="type === 'datetime'">
      <el-date-picker
        @change="sendValue"
        v-model="value"
        type="datetime"
        :value-format="format"
        :format="format"
        :disabled="data.disabled"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      format: "",
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "选择当前时间",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value(val) {
      this.sendValue();
    },
  },
  mounted() {
    console.log(this.data, this.format);
    if (this.format === "yyyy-MM" || this.format === "yyyy-MM-dd") {
      this.type = "date";
    } else {
      this.type = "datetime";
    }
    if (this.draftData.value) {
      this.value = this.draftData.value;
    } else {
      this.value = this.data.__config__.defaultValue;
    }

    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
    //this.type = this.data.type;
  },
  methods: {
    sendValue(value) {
      let obj = {
        name: this.data.__vModel__,
        value: this.value,
        index: this.index,
      };
      this.$emit("tableValue", obj);
    },
  },

  created() {
    this.format = this.data.format;
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-date-editor {
  width: 100%;
}
</style>
