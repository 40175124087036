<template>
  <div>
    <div
      class="richText"
      :style="{ color: data.textColor }"
      v-if="data.showLineLabel"
    >
      {{ data.textLabel }}
    </div>
    <div class="descBox" v-if="data.lienDescribe">
      {{ data.lienDescribe }}
    </div>
    <div class="line" :style="{ backgroundColor: data.backgroundColor }"></div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.richText {
  font-size: 14px;
}
.descBox {
  font-size: 12px;
  color: #999;
  margin: 8px 0;
}
</style>
