import request from "@/utils/request";
// 用户登录
export function getUserLogin(data) {
  return request({
    url: "/api/login/login",
    method: "post",
    data,
  });
}
//获取顶级部门  type类型为all的话，会查询组织下面的所有，如果不等于all就是查询当前有的
export function getDepartMent(type) {
  return request({
    url: "/api/user/departmentList",
    method: "get",
    params: type,
  });
}

//获取部门成员
export function getDepartMember(data) {
  return request({
    url: "/api/user/departmentUserList",
    method: "get",
    params: data,
  });
}

//新增部门成员
export function getAddDepartMember(data) {
  return request({
    url: "/api/user/addUser",
    method: "post",
    data,
  });
}

//删除成员
export function getDelDepartMember(id) {
  return request({
    url: "/api/user/userDelte",
    method: "post",
    data: {
      id,
    },
  });
}

//修改成员
export function getEditDepartMember(data) {
  return request({
    url: "/api/user/userUpdate",
    method: "post",
    data,
  });
}

//部门新增成员
export function getAddDepart(data) {
  return request({
    url: "/api/user/addDepartment",
    method: "post",
    data,
  });
}
//修改部门名称
export function getUpdateDepart(data) {
  return request({
    url: "/api/user/updateDepartment",
    method: "post",
    data,
  });
}

//修改人员状态
export function getEditStatus(data) {
  return request({
    url: "/api/user/userUpdateStatus",
    method: "post",
    data,
  });
}

//新增部门
export function getAddDepartList(data) {
  return request({
    url: "/api/user/addDepartment",
    method: "post",
    data,
  });
}

//删除部门
export function getDelDepartList(id) {
  return request({
    url: "/api/user/deleteDepartment",
    method: "post",
    data: {
      id,
    },
  });
}

//获取角色列表
export function getRoleList(type) {
  return request({
    url: "/api/role/roleList",
    method: "get",
    params: type,
  });
}

//获取角色成员列表
export function getRoleMemberList(data) {
  return request({
    url: "/api/role/roleUserList",
    method: "get",
    params: data,
  });
}

//添加角色成员
export function getAddRoleMember(data) {
  return request({
    url: "/api/role/roleUserAdd",
    method: "post",
    data,
  });
}

//移除角色成员
export function getDelRoleMember(data) {
  return request({
    url: "/api/role/roleUserDelete",
    method: "post",
    data,
  });
}

//添加角色组
export function getAddRoleGroup(name) {
  return request({
    url: "/api/role/addRoleGroup",
    method: "post",
    data: {
      name,
    },
  });
}
//修改角色和角色组名称
export function getUpdateRoleGroup(data) {
  return request({
    url: "/api/role/updateRoleGroup",
    method: "post",
    data,
  });
}

//删除角色组
export function getDelRoleGroup(id) {
  return request({
    url: "/api/role/deleteRoleGroup",
    method: "post",
    data: {
      id,
    },
  });
}
//删除角色
export function getDelRole(id) {
  return request({
    url: "/api/role/deleteRole",
    method: "post",
    data: {
      id,
    },
  });
}
//添加角色
export function getAddRole(data) {
  return request({
    url: "/api/role/addRole",
    method: "post",
    data,
  });
}

//管理员列表
export function getAdminList(data) {
  return request({
    url: "/api/permissions/permissionsList",
    method: "post",
    params: data,
  });
}
//修改管理员
export function getAdminListEdit(data) {
  return request({
    url: "/api/permissions/permissionsUpdate",
    method: "post",
    data,
  });
}

//添加管理员
export function getAdminListAdd(data) {
  return request({
    url: "/api/permissions/permissionsAdd",
    method: "post",
    data,
  });
}

//移除管理员
export function getAdminListDel(id) {
  return request({
    url: "/api/permissions/permissionsDelete",
    method: "post",
    data: {
      id,
    },
  });
}

//查看管理员权限
export function getAdminPermissions(id) {
  return request({
    url: `/api/permissions/permissionsDetail?id=${id}`,
    method: "get",
  });
}

// 通讯录 权限
export function getTxPermissions() {
  return request({
    url: `/api/login/userInfo`,
    method: "get",
  });
}
// 获取钉钉企业微信参数
export function getSecretkeyList(type) {
  return request({
    url: `/api/synchronization/getSecretkey?type=${type}`,
    method: "get",
  });
}
// 未同步可以修改参数数据  保存钉钉参数
export function getAddSecretkey(data) {
  return request({
    url: `/api/synchronization/addSecretkey`,
    method: "post",
    data,
  });
}
// 修改参数
export function getUpdateSecretkey(data) {
  return request({
    url: `/api/synchronization/updateSecretkey`,
    method: "post",
    data,
  });
}
// 同步
export function getDdDepartment() {
  return request({
    url: `/api/synchronization/getDdDepartment`,
    method: "get",
  });
}
// 获取钉钉的用户信息
export function getUserAll(page, limit) {
  return request({
    url: `/api/synchronization/getUserAll?page=${page}&limit=${limit}`,
    method: "get",
  });
}
// 获取钉钉的用户信息
export function getUserInfo(page) {
  return request({
    url: `/api/synchronization/getUserInfo?page=${page}`,
    method: "get",
  });
}
// 钉钉绑定信息
export function getBinding(data) {
  return request({
    url: `/api/synchronization/binding`,
    method: "post",
    data,
  });
}
// 钉钉解绑信息
export function getUnbind(data) {
  return request({
    url: `/api/synchronization/unbind`,
    method: "post",
    data,
  });
}
//企业微信同步信息
export function getWechatSynchronization() {
  return request({
    url: `api/WechatSynchronization/index`,
    method: "get",
  });
}
// 获取登录验证码
export function getCaptcha() {
  return request({
    url: `/api/login/captcha`,
    method: "get",
    responseType: "blob",
  });
}
// 钉钉免登录
export function getDdLogin(data) {
  return request({
    url: `/api/login/ddLogin`,
    method: "post",
    data,
  });
}
// 企业微信单点登录
export function geWechatSynchronization(data) {
  return request({
    url: `/api/WechatSynchronization/login`,
    method: "post",
    data,
  });
}

// 通讯录新增部门主管
export function addDepartmentManager(data) {
  return request({
    url: "/api/user/addDepartmentManager",
    method: "post",
    data,
  });
}
