import request from "@/utils/request";

//获取表单列表
export function getChartsSetting(data) {
  return request({
    url: `/api/instrument/getDataCalculation`,
    method: "post",
    data,
  });
}

// 明细表
export function getdetailsTable(data) {
  return request({
    url: `/api/instrument/getDataCalculationList`,
    method: "post",
    data,
  });
}

//新建BI仪表盘
export function getCreateBiForm(appId, groupId) {
  return request({
    url: `/api/bi/create?app_id=${appId}&group_id=${groupId}`,
    method: "get",
  });
}

//修改BI样式
export function getUpdateBiCss(id, formId, data) {
  return request({
    url: `/api/bi/update?app_id=${id}&form_id=${formId}`,
    data,
    method: "post",
  });
}

//获取BI表单详情数据
export function getBiFormDetail(formId, biId) {
  return request({
    url: `/api/bi/getBiSub?formId=${formId}&bi_id=${biId}`,
    method: "get",
  });
}
//保存BI图表组件配置
export function getBiChartsSetting(data) {
  return request({
    url: "/api/bi/updateSub",
    method: "post",
    data,
  });
}

//获取辅助线动态值
export function getSummaryFiled(data) {
  return request({
    url: `api/instrument/getSummaryFiled?data_source_id=${data.data_source_id}&field=${data.field}&method=${data.method}&summary=${data.summary}&group_where=${data.group_where}`,
    method: "get",
  });
}
// 仪表盘公开发布
export function releaseBI(data) {
  return request({
    url: `api/publicrelease/setBiUrl?app_id=${data.app_id}&form_id=${data.form_id}`,
    method: "post",
    data,
  });
}
// 仪表盘公开发布信息回显
export function getopenInfoApi(form_id) {
  return request({
    url: `api/publicrelease/getBiUrl?form_id=${form_id}`,
    method: "get",
  });
}
// 判断仪表盘公开发布是否过期
export function checkUrl(form_id) {
  return request({
    url: `api/publicrelease/checkBiUrl?form_id=${form_id}`,
    method: "get",
  });
}

//数据透视图数据列表
export function getPerpectiveList(data) {
  return request({
    url: `/api/instrument/test1`,
    data,
    method: 'post'
  })
}
