import request from "@/utils/request";
//获取打印目标列表
export function getPrintList(appId, formId) {
  return request({
    url: `/api/luckysheet/list?app_id=${appId}&form_id=${formId}`,
    method: "get",

  })
}
// 新增打印模板
export function getCreatePrint(data) {
  return request({
    url: "/api/luckysheet/create",
    method: "post",
    data,
  });
}
//删除打印模板
export function getDeletePrint(data) {
  return request({
    url: `/api/luckysheet/del`,
    method: "post",
    data
  })
}

//复制打印模板
export function getCopyPrint(data) {
  return request({
    url: `/api/luckysheet/copy`,
    method: "post",
    data
  })
}
//保存模板设置
export function getSettingPrint(data) {
  return request({
    url: `/api/luckysheet/update`,
    method: "post",
    data
  })
}

//查询模板详情
export function getDetailPrint(id) {
  return request({
    url: `/api/luckysheet/detail?id=${id}`,
    method: "get",

  })

}

//获取pdf
export function getPrintPdf(formId, templateId, form_detail_id) {
  return request({
    url: `/api/luckysheet/generatePdf?formId=${formId}&templateId=${templateId}&form_detail_id=${form_detail_id}`,
    method: "get",
    responseType: 'blob',
  })
}
//获取word PDF
// export function getPrintWordPdf(formId, templateId, form_detail_id) {
//   return request({
//     url: `api/wordtemp/renderTemplate?formId=${formId}&templateId=${templateId}&form_detail_id=${form_detail_id}`,
//     method: "get",
//     responseType: 'blob',
//   })
// }
