<template>
  <div class="moduleScreen">
    <my-input :data="moduleList" v-if="moduleList.__config__.tag == 'el-input'" @value="handleValue"
      @FrontEndEvent_1="FrontEndEvent_1"></my-input>

    <my-input-number :data="moduleList" v-else-if="moduleList.__config__.tag == 'inputNumber'"
      @value="handleValue"></my-input-number>

    <my-select :data="moduleList" v-else-if="moduleList.__config__.tag == 'el-select'" @value="handleValue"></my-select>

    <my-cascader :data="moduleList" v-else-if="moduleList.__config__.tag == 'el-cascader'"
      @value="handleValue"></my-cascader>

    <my-radio :data="moduleList" v-else-if="moduleList.__config__.tag == 'el-radio-group' ||
      moduleList.__config__.tag == 'el-checkbox-group'
      " @value="handleValue"></my-radio>

    <my-time :data="moduleList" v-else-if="moduleList.__config__.tag == 'datePicker'" @value="handleValue"></my-time>

    <my-upload :data="moduleList" v-else-if="moduleList.__config__.tag == 'el-upload'" @value="handleValue"
      :formId="formId" :appId="appId"></my-upload>
    <my-addr :data="moduleList" v-else-if="moduleList.__config__.tag == 'addr'" @value="handleValue"></my-addr>
    <my-depart :data="moduleList" v-else-if="moduleList.__config__.tag == 'departCheck'" @value="handleValue"></my-depart>
    <my-depart-single :data="moduleList" v-else-if="moduleList.__config__.tag == 'departSingle'"
      @value="handleValue"></my-depart-single>
    <my-menber-single :data="moduleList" v-else-if="moduleList.__config__.tag == 'menberSingle'"
      @value="handleValue"></my-menber-single>
    <my-menber :data="moduleList" v-else-if="moduleList.__config__.tag == 'menberCheck'" @value="handleValue"></my-menber>

    <my-table :data="moduleList" :formId="formId" v-else-if="moduleList.__config__.tagIcon == 'row' &&
      moduleList.__config__.isDisplay
      " @value="handleValue" :flowRecord="flowRecord" :tableRuleType="tableRuleType"></my-table>
    <my-line :data="moduleList" v-else-if="moduleList.__config__.tagIcon == 'Line'"></my-line>
    <my-sign :data="moduleList" v-else-if="moduleList.__config__.tagIcon == 'Sign'"></my-sign>
    <my-position :data="moduleList" v-else-if="moduleList.__config__.tagIcon == 'Position'"></my-position>
    <my-serial :data="moduleList" v-else-if="moduleList.__config__.tag === 'el-serial'"></my-serial>
    <my-editor :data="moduleList" @value="handleValue" v-else-if="moduleList.__config__.tag === 'el-editor'"></my-editor>
    <my-extend-button :data="moduleList" @value="handleValue"
      v-else-if="moduleList.__config__.tag === 'extend-button'"></my-extend-button>
    <my-img-upload :data="moduleList" v-else-if="moduleList.__config__.tag === 'img-upload'"
      @value="handleValue"></my-img-upload>
    <myRelationData :data="moduleList" v-else-if="moduleList.__config__.tag === 'relationData'" @value="handleValue"
      :formDataFields="formDataFields" :formId="formId">
    </myRelationData>
    <myRelationSearch :data="moduleList" v-else-if="moduleList.__config__.tag === 'relationSearch'" @value="handleValue"
      :formDataFields="formDataFields" :formId="formId"></myRelationSearch>
    <myDataLoad :data="moduleList"  v-else-if="moduleList.__config__.tag === 'dataLoad'" @value="handleValue" @databack="databack"
      :formDataFields="formDataFields" :formId="formId"></myDataLoad>
  </div>
</template>
 
<script>
import myInput from "./myInput.vue";
import myInputNumber from "./myInputNumber.vue";
import mySelect from "./mySelect.vue";
import myCascader from "./myCascader.vue";
import myRadio from "./myRadio.vue";
import myTime from "./myTime.vue";
import myUpload from "./myUpload.vue";
import myAddr from "./myAddr.vue";
import myDepart from "./myDepart.vue";
import myMenber from "./myMenber.vue";
import myTable from "./myTable.vue";
import myLine from "./myLine.vue";
import mySign from "./mySign.vue";
import myPosition from "./myPostion.vue";
import mySerial from "./mySerial.vue";
import myEditor from "./myEditor.vue";
import myDepartSingle from "./myDepartSingle.vue";
import myMenberSingle from "./myMenberSingle.vue";
import myExtendButton from "./myExtendButton.vue";
import myImgUpload from "./myImgUpload.vue";
import myRelationData from "./myRelationData.vue";
import myRelationSearch from "./myRelationSearch.vue";
import myDataLoad from "./myDataLoad.vue";

export default {
  props: {
    moduleList: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    tableRuleType: {
      type: String,
    },
    flowRecord: {
      type: Array,
    },
    formDataFields: {
      type: Object,
    }
  },

  name: "ModuleScreen",
  data () {
    return {
      formData: {},
    };
  },
  components: {
    myInput,
    myDataLoad,
    myInputNumber,
    mySelect,
    myCascader,
    myRadio,
    myAddr,
    myTime,
    myDepart,
    myUpload,
    myMenber,
    myTable,
    myLine,
    mySign,
    myPosition,
    mySerial,
    myEditor,
    myDepartSingle,
    myMenberSingle,
    myExtendButton,
    myImgUpload,
    myRelationData,
    myRelationSearch,
  },
  created () { },
  methods: {
    handleValue (val) {
      this.$emit("value", val);
    },
    FrontEndEvent_1 (val) {
      this.$emit("clickModuleScreen", val)
    },
    databack(val){
      this.$emit("databack",val)
    }
  },
  mounted () { }
};
</script>

<style scoped lang="scss">
.el-rate {
  display: inline-block;
}
</style>
