<!--  -->
<template>
  <div>
    <div class="header-warp">
      <div class="header-left">
        <img style="width: 150px; height: 40px" src="../../assets/loginLogin2.png" alt="" v-if="nowRoute === 'home'" />
        <div class="header" v-else-if="nowRoute === 'todolist'">
          <i class="iconfont icon-zhankaicaidan" @click="onStaging"></i>
          <div class="matter">
            <div class="matter-icon" :style="{ background: JSON.parse(appName).color }">
              <i :class="'iconfont-1 ' + JSON.parse(appName).icon"></i>
            </div>
            <div>{{ JSON.parse(appName).name }}</div>
          </div>
        </div>
        <div class="header" v-else-if="nowRoute === 'management'">
          <i class="iconfont icon-fanhui" @click="$router.go(-1)"></i>
          <!-- <i class="iconfont icon-zhankaicaidan" @click="onStaging"></i> -->
          <div class="matter">
            <div class="matter-icon">
              <i class="iconfont icon-shuju5"></i>
            </div>
            <div>仪表盘权限/表单</div>
          </div>
        </div>
        <div class="header" v-else-if="nowRoute === 'commission'">
          <i class="iconfont icon-fanhui" @click="$router.go(-1)"></i>
          <!-- <i class="iconfont icon-zhankaicaidan" @click="onStaging"></i> -->
          <div class="matter">
            <div class="matter-icon">
              <img v-if="$store.state.user.listType == 0" style="width: 28px" src="../../assets/icon1.png" alt="" />
              <img v-if="$store.state.user.listType == 1" style="width: 28px" src="../../assets/icon2.png" alt="" />
              <img v-if="$store.state.user.listType == 2" style="width: 28px" src="../../assets/icon3.png" alt="" />
              <img v-if="$store.state.user.listType == 3" style="width: 28px" src="../../assets/icon1.png" alt="" />
              <img v-if="$store.state.user.listType == 4" style="width: 28px" src="../../assets/icon4.png" alt="" />

            </div>
            <div v-if="$store.state.user.listType == 0">我的待办</div>
            <div v-if="$store.state.user.listType == 1">我处理的</div>
            <div v-if="$store.state.user.listType == 2">我发起的</div>
            <div v-if="$store.state.user.listType == 3">抄送我的</div>
            <div v-if="$store.state.user.listType == 4">发起流程</div>
          </div>
        </div>
        <div class="header" v-else-if="nowRoute === 'communicate'">
          <i class="iconfont icon-fanhui" @click="$router.go(-1)"></i>
          <div class="matter">
            <i class="iconfont icon-V" style="color: blue; margin: 0 10px"></i>

            <div>通讯录</div>
          </div>
        </div>
        <div class="router-list" v-if="nowRoute != 'home'">
          <div @click="goHome">主页 /</div>
          <div>{{ $route.meta.title }}</div>
          <!-- <div>
            {{
              $store.state.app.communicateNav === 0
                ? "部门"
                : $store.state.app.communicateNav === 1
                ? "角色"
                : "管理员"
            }}
          </div> -->
        </div>
      </div>
      <div class="header-right">
        <div v-if="$route.path !== '/recycle' && $route.path !== '/communicate'" style="display: flex">
          <i class="iconfont icon-huishouzhandierban" style="margin: 0px"></i><span @click="goHSZ">回收站</span>
        </div>
        <div v-if="nowRoute != 'communicate' &&
          $store.state.user.PermissionsList.auth.display == 1
          " style="display: flex">
          <i class="iconfont icon-V" style="margin-right: 0px"></i>
          <span @click="goTXL">通讯录</span>
        </div>

        <i class="iconfont icon-gengduo-hengxiang"></i>
        <i class="iconfont icon-liuyan" @click="handMessage"></i>
        <i class="iconfont icon-huiyuan21"></i>
        <div class="line"></div>
        <el-popover placement="top-start" trigger="click">
          <div class="name" slot="reference">
            {{ $store.state.user.PermissionsList.name }}
          </div>
          <div style="text-align: center; cursor: pointer" @click="outLogin">
            退出登录
          </div>
        </el-popover>
      </div>
    </div>
    <staging :show="stagingShow" @handleClose="handleClose"></staging>
    <message :drawer.sync="drawer"></message>
  </div>
</template>

<script>
import staging from "@/components/staging";
import message from "@/components/message/index.vue";
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      nowRoute: "home",
      stagingShow: false,
      drawer: false,
    };
  },
  components: {
    staging,
    message,
  },
  computed: {
    ...mapGetters(["appName"]),

  },
  mounted () {
  },
  watch: {
    $route () {
      this.nowRoute = this.$store.state.app.routeName;
      if (this.$route.query.type != "bi") {
        this.$store.dispatch("app/setClearLeftNav");
      }
    },
  },

  methods: {
    goHome () {
      this.$store.dispatch("user/actionSetWeChatLg");
      this.$router.push("/home");
    },
    goTXL () {
      this.$router.push("/communicate");
    },
    goHSZ () {
      if (this.$route.path == "/home") {
        this.$router.push({
          path: "/recycle",
          query: {
            type: "app",
          },
        });
      } else {
        this.$router.push({
          path: "/recycle",
          query: {
            type: "form",
            ids: this.$route.query.id,
          },
        });
      }
    },
    onStaging () {
      this.stagingShow = true;
    },
    handleClose () {
      this.stagingShow = false;
    },
    outLogin () {
      this.$router.push("/login");
      this.$store.dispatch("user/logoutAction");
    },
    handMessage () {
      this.drawer = true;
    },
  },
  created () {
    this.nowRoute = this.$store.state.app.routeName;
  },
};
</script>
<style lang="scss" scoped>
.header-warp {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 54px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;

  .header-right {
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
      color: #909399;
      cursor: pointer;
      margin-right: 20px;
    }

    span {
      font-size: 14px;
      display: block;
      cursor: pointer;
      color: #606266;
      margin-left: 5px;
      margin-right: 20px;
    }

    .line {
      width: 1px;
      height: 18px;
      background: #dadee7;
      border-radius: 1px;
      margin-right: 20px;
    }

    .name {
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      color: #606266;
    }
  }
}

.router-list {
  display: flex;
  align-items: center;
  margin-left: 20px;

  div {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: #909399;
  }

  &>div:nth-child(3) {
    color: #409eff;
  }
}

.header-left {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  width: 220px;

  i {
    cursor: pointer;
    font-size: 20px;
    color: #000;
  }

  .matter {
    display: flex;
    align-items: center;

    .matter-icon {
      width: 24px;
      height: 24px;
      background: #4fd6b8;
      border-radius: 4px;
      text-align: center;
      line-height: 24px;
      margin-right: 10px;
      margin-left: 10px;

      i {
        color: #fff;
      }
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #303133;
    }
  }
}
</style>
