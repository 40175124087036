<template>
  <div class="funnel-warp">
    <div class="funnel-count" v-if="type !== '4'" style="height: 100%">
      <div
        :id="id"
        :style="{
          height: '100%',
          background:
            option.cssStyle.bgType === '1'
              ? option.cssStyle.bgColor
              : `url(${option.cssStyle.bgUrl}) `,
        }"
      ></div>
    </div>

    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",

      chartType: "1",
      id: "funnel",
    };
  },
  components: {},
  mounted() {},
  props: ["option", "chartData", "defaultColor", "colorList", "chartId"],

  watch: {
    option: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },

    colorList: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    type(val) {},
  },
  methods: {
    getList(data, chartData) {
      let len = data.draggableObj.dimensionList.length;
      let len2 = data.draggableObj.targatList.length;

      if (len === 1 && len2 >= 1) {
        this.type = "1";
        this.$nextTick(() => {
          this.initChart(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 0 && len2 > 0) {
        this.type = "2";
        this.$nextTick(() => {
          this.initChart2(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else {
        this.type = "4";
      }
    },
    initChart(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var option;
      if (myChart) {
        myChart.clear();
      }
      let legendList = [];
      chartData[0].xAxis.forEach((item) => {
        legendList.push(item);
      });
      let dataList = [];
      chartData[0].yAxis.forEach((item, index) => {
        dataList.push({
          value: item,
          name: chartData[0].xAxis[index],
        });
      });
      console.log(dataList);
      let colorArr = [];
      if (this.colorList.length > 0) {
        this.colorList.forEach((item) => {
          colorArr.push(item.color);
        });
      } else {
        colorArr = this.defaultColor;
      }
      let that = this;

      option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            let result = "";
            chartData.forEach((item, index) => {
              item.xAxis.forEach((item2, index2) => {
                if (params.data.name === item2) {
                  let countData = that.getPercent(
                    index,
                    params,
                    chartData,
                    chartData[index].yAxis[index2]
                  );
                  result += item.zb_name + ":" + countData + "<br/>";
                }
              });
            });

            return params.name + "<br/>" + result;
          },
        },

        legend: {
          data: legendList,
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },
        color: colorArr,
        series: [
          {
            type: "funnel",
            left: "20%",
            top: 60,
            bottom: 60,
            width: "60%",
            min: 0,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",

            gap: 2,
            label: {
              show: true,
              position: "right",
              formatter: function (params) {
                let result = "";

                chartData.forEach((item, index) => {
                  item.xAxis.forEach((item2, index2) => {
                    if (params.data.name === item2) {
                      let countData = that.getPercent(
                        index,
                        params,
                        chartData,
                        chartData[index].yAxis[index2]
                      );

                      result += item.zb_name + ":" + countData + ",";
                    }
                  });
                });
                let dimension = that.option.funnel.showDimension
                  ? params.name + ":"
                  : "";
                let targat = that.option.funnel.showTargat ? result : "";
                targat = targat.substring(0, targat.lastIndexOf(","));
                return dimension + targat;
              },
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: dataList,
          },
        ],
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    initChart2(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var that = this;
      var option;
      if (myChart) {
        myChart.clear();
      }
      let legendList = [];
      data.draggableObj.targatList.forEach((item) => {
        legendList.push(item.__config__.label);
      });

      let colorArr = [];
      if (this.colorList.length > 0) {
        this.colorList.forEach((item) => {
          colorArr.push(item.color);
        });
      } else {
        colorArr = this.defaultColor;
      }
      let dataList = [];
      chartData.forEach((item) => {
        dataList.push({
          value: item.yAxis[0],
          name: item.zb_name,
        });
      });
      option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (
              chartData[params.dataIndex].advanced === "proportion" ||
              that.option.funnel.showPercent
            ) {
              return params.name + " : " + params.percent + "%";
            } else {
              return params.name + " : " + params.value;
            }
          },
        },

        legend: {
          data: legendList,
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },
        color: colorArr,
        series: [
          {
            type: "funnel",

            top: 60,
            bottom: 60,
            left: "20%",
            width: "60%",
            min: 0,

            minSize: "0%",
            maxSize: "100%",

            sort: "descending",

            gap: 2,
            label: {
              show: true,
              position: "right",
              formatter: function (params) {
                let dimension = that.option.funnel.showDimension
                  ? params.name
                  : "";
                let targat = that.option.funnel.showTargat ? params.value : "";
                if (that.option.yAxis.showData) {
                  if (
                    chartData[params.dataIndex].advanced === "proportion" ||
                    that.option.funnel.showPercent
                  ) {
                    return dimension + " : " + targat + "%";
                  } else {
                    return dimension + " : " + targat;
                  }
                } else {
                  return "";
                }
              },
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 20,
              },
            },
            data: dataList,
          },
        ],
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    getPercent(i, params, chartData, value) {
      let sumArray = chartData[i].yAxis.reduce((sum, num) => sum + num, 0);

      let count = ((value / sumArray) * 100).toFixed(2) + "%";
      if (
        chartData[i].advanced === "proportion" ||
        this.option.funnel.showPercent
      ) {
        return count;
      } else {
        return value;
      }

      // console.log(i, params, chartData, dataList);
    },
  },
  created() {},
  mounted() {
    if (this.chartId) {
      this.id = this.chartId;
    }
    this.getList(this.option, this.chartData);
  },
};
</script>

<style lang="scss" scoped>
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.funnel-warp {
  position: relative;
  height: 100%;
  width: 100%;

  #funnel {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-attachment: fixed;
  }
}
</style>
