<template>
  <div>
    <!-- 关联数据 -->
    <!-- <div>123123123</div> -->
    <div class="btn">
      点击选择数据
    </div>
    <dataDetails :headData="gl_field_filter"></dataDetails>
  </div>
</template>
<script>
import dataDetails from "@/components/relatedDatatable/dataDetails.vue"
export default {
  name: 'relationData',
  components: {
    dataDetails
  },
  data () {
    return {
    }
  },
  watch: {
    'gl_field_filter': {
      handler (val) {
        console.log(val, "arr")
      },
      deep: true
    }
  },
  props: ["gl_field_filter"],
  methods: {},
  mounted () {
  },
}
</script>
<style lang="scss" scoped>
.btn {
  width: 70%;
  min-width: 82px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
</style>
