<template>
  <div>
    <div class="title">{{ data.__config__.label }}</div>
    <div class="descBox">
    </div>
    <div class="btn" @click="openDialog">{{ data.buttonName }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler (val) {
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    openDialog () {
      this.$bus.$emit("extendFun", this.data)
    }
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #303133;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  width: 50px;
  background-color: #0265ff;
  border-radius: 4px;
}
</style>