import {
  bignumber,
  chain
} from "mathjs";
//根据math.js加减乘除计算封装,参数数字只能number和null类型,当数字超过2^54-1时，可能会出现精度问题
//因为引入chain，即便是按需引入，可能由于chain涉及到的数据方法太多，导致打包时，dist文件直接大了1M左右。
export const calculate = {
  comp: function (_func, args) {
    let t = chain(bignumber(args[0]));
    for (let i = 1; i < args.length; i++) {
      if (typeof args[i] === "number" || typeof args[i] === "null") {
        t = t[_func](bignumber(args[i]));
      }
    }
    // 防止超过6位使用科学计数法
    return parseFloat(t.done());
  },
  //加
  add: function () {
    return this.comp("add", arguments);
  },
  //减
  subtract: function () {
    return this.comp("subtract", arguments);
  },
  //乘
  multiply: function () {
    return this.comp("multiply", arguments);
  },
  //除
  divide: function () {
    return this.comp("divide", arguments);
  },
};
