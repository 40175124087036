<template>
	<div class="tools-box">
		<colorDialog v-if="isColor && openDialog" :openDialog="openDialog" :datas="datas" :position="positionFun(1)" @handleClose="handleClose"></colorDialog>
		<imgDialog v-if="!isColor && datas.data && datas.data.type == 'img' && openDialog" :openDialog="openDialog" :datas="datas" :position="positionFun(1)" :positionTow="positionFun(2)" @handleClose="handleClose"></imgDialog>
		<timeDialog v-if="!isColor && datas.data && datas.data.type == 'time' && openDialog" :openDialog="openDialog" :datas="datas" :position="positionFun(1)" :positionTow="positionFun(2)" @handleClose="handleClose"></timeDialog>
		<linkDialog v-if="!isColor && datas.data && datas.data.type == 'link' && openDialog" :noglink="noglink" :openDialog="openDialog" :datas="datas" @handleClose="handleClose"></linkDialog>
		<iframeDialog v-if="!isColor && datas.data && datas.data.type == 'iframe' && openDialog" :openDialog="openDialog" :datas="datas" :position="positionFun(1)" @handleClose="handleClose"></iframeDialog>
	</div>
	
</template>

<script>
	import { cloneDeep } from "lodash";
	import VueGridLayout from 'vue-grid-layout';
	import colorDialog from "./colorDialog/colorDialog";
	import imgDialog from "./imgGrid/imgDialog";
	import timeDialog from "./timeGrid/timeDialog";
	import linkDialog from "./linkGrid/linkDialog";
	import iframeDialog from "./iframeGrid/iframeDialog";
	
  export default {
		props: {
			opennum: 0,
			opencolornum: 0,
		  datas: Object,
		  noglink: true
		},
    data() {
      return {
				isColor: false,
				openDialog: false,
				scrollTop: 0
      };
    },
		watch: {
			opennum(val) {
				this.isColor = false;
				this.openFun()
			},
			opencolornum(val) {
				this.isColor = true;
				this.openFun()
			},
		},
    components: {
			colorDialog,imgDialog,timeDialog,linkDialog,iframeDialog
    },
		created() {
		},
		mounted() {
			document.getElementById("grid-cont-box").addEventListener('scroll', this.handleScroll);
		},
    methods: {
			openFun(){
				this.openDialog = true
			},
			handleClose(){
				this.openDialog = false
			},
			handleScroll(event) {
				const scrollDistance = event.target.scrollTop;
				this.scrollTop = scrollDistance*1;
			},
			positionFun(num){
				let str = ``;
				let top = 48;
				let left = 168;
				let margin = 10;
				let h = 30;
				let dialog_w = 338;
				let dialog_h = 445;
				let parentRect = document.getElementById("grid-cont-box").getBoundingClientRect();
				let par_col = (parentRect.width-250)/24;
				
				let set_top = 0;
				let set_left = 0;
				let grid_top = top+this.datas.y*h+margin*(this.datas.y+1);
				let grid_bottom = top+this.datas.y*h+margin*(this.datas.y+1+this.datas.h)+this.datas.h*h;
				let grid_left = left+this.datas.x*par_col+margin*(this.datas.x+1);
				let grid_right = left+this.datas.x*par_col+margin*(this.datas.x+1+this.datas.w)+this.datas.w*par_col;
				
				if(num === 1){
					if((grid_right+dialog_w)>=(parentRect.width+left)){
						str += `padding-left:`+(grid_right-10-dialog_w)+`px;`
					}else{
						str += `padding-left:`+(grid_right-10)+`px;`
					}
				}else{
					if((grid_right+dialog_w*2)>=(parentRect.width+left) && (grid_right+dialog_w)>=(parentRect.width+left)){
						str += `padding-left:`+(grid_right-10-dialog_w*2)+`px;`
					}else if((grid_right+dialog_w)<(parentRect.width+left) && (grid_right+dialog_w*2)>=(parentRect.width+left)){
						str += `padding-left:`+(grid_right-10-dialog_w)+`px;`
					}else{
						str += `padding-left:`+(grid_right-10+dialog_w)+`px;`
					}
				}
				
				if((grid_top+dialog_h-this.scrollTop)>=(parentRect.height+top)){
					str += `padding-top:`+(parentRect.height+top-dialog_h)+`px;`
				}else{
					str += `padding-top:`+(grid_top-this.scrollTop)+`px;`
				}
				
				return str
			}
    },
  };
</script>

<style lang="scss" scoped>
	
	::v-deep .el-dialog__header{
		display: none;
	}
	
	::v-deep .el-dialog__body{
		padding: 26px;
	}
	
	::v-deep .el-upload--picture-card{
		width: 135px;
		height: 90px;
		line-height: 90px;
	}
	::v-deep .el-upload-list--picture-card .el-upload-list__item{
		width: 132px;
		height: 90px;
	}
	
	::v-deep .setDialog .el-dialog{
		margin: 0 !important;
	}
	
	::v-deep .dialog-cont,::v-deep .body-box{
		font-size: 12px;
		color: #303133;
		.lable{
			margin: 10px 0;
			font-size: 14px;
			font-weight: bold;
		}
		.tips{
			margin-bottom: 10px;
		}
		.el-radio{
			// margin-right: 18px;
			.el-radio__label{
				font-size: 12px;
			}
		}
	}
	::v-deep .dialog-cont>.lable:first-child{
		margin-top: 0;
	}
	::v-deep .one-line{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>