<template>
  <div>
    <div>
      <dataDetails :data="valueData" :headData="headData" :formId="formId" :multi="queryParams.multi" @getPage="getPage">
      </dataDetails>
    </div>
  </div>
</template>

<script>
import dataDetails from "@/components/relatedDatatable/dataDetails.vue"
import { getRelatedData } from "@/api/app"
export default {
  components: {
    dataDetails
  },
  props: ["data", "formDataFields", "formId"],
  data () {
    return {
      valueData: [],
      headData: [],
      pageSize: 10,
      pageNum: 1,
      queryParams: {
        // true多行 false单行
        multi: false
      },
      // 用来判断数据有没有发生改变
      timeoutId: ""
    }
  },
  watch: {
    "formDataFields": {
      handler (val) {
        // 监听数据 如果5秒内数据没有发生改变的话 就执行操作
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        console.log(this.data.relationData.conditionList, "为什么不生效")
        // 判断后台配置里面有没有传入条件  然后用后台传入条件的值去匹配前台输入的值
        if (this.data.relationData.conditionList.length > 0) {
          this.data.relationData.conditionList.forEach(item => {
            val.fields.forEach(item1 => {
              if (item.filterData.zd_data === item1.__vModel__) {
                if (item1.__config__.defaultValue) {
                  item.filterData.value = item1.__config__.defaultValue
                }
              }
            })
          })
          this.queryParams.filter = this.data.relationData
        }

        this.timeoutId = setTimeout(() => {
          console.log("5秒后执行")
          this.getData()
        }, 3000);


      },
      deep: true
    },
    'data': {
      handler (val) {
        if (val) {
          this.headData = val.gl_field_filter
          if (val.num_radio === '1') {
            this.queryParams.multi = false
          } else {
            this.queryParams.multi = true
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getData () {
      this.queryParams.pageNum = this.pageNum
      this.queryParams.pageSize = this.pageSize
      this.queryParams.fields = this.data.gl_field_filter
      this.queryParams.app_id = this.$route.query.id,
        this.queryParams.form_id = this.data.gl_table_value

      // 判断后台配置里面有没有传入条件  然后用后台传入条件的值去匹配前台输入的值
      if (this.data.relationData.conditionList.length > 0) {
        const allConditionsValid = this.data.relationData.conditionList.every(item => {
          return this.formDataFields.fields.some(item1 => {
            return item.filterData.zd_data === item1.__vModel__ && item1.__config__.defaultValue || (item.filterData.filterType === '2' && item.filterData.value);
          });
        });

        // 如果所有条件都匹配，执行后续操作
        if (allConditionsValid) {
          getRelatedData(this.queryParams).then(res => {
            if (res.code === 200) {
              this.valueData = res.data.data
              let gl_data = {
                entryId: this.data.gl_table_value,
                id: this.valueData._id,
                valueData: this.valueData,
                multi: this.queryParams.multi
              }

              let obj = {
                name: this.data.__vModel__,
                value: gl_data
              }
              console.log(obj)

              this.$emit("value", obj)
            }
          })

        }
      }

    },
    getPage (pageSize, pageNum) {
      this.pageSize = pageSize
      this.pageNum = pageNum
      this.getData()
    }

  },
  mounted () {

  }

}
</script>