<template>
  <el-cascader
    v-model="data.__config__.defaultValue"
    :options="data.options"
    :props="data.props.props"
    :placeholder="data.placeholder"
    :filterable="data.filterable"
    :clearable="data.clearable"
    :disabled="data.disabled"
    :style="data.style"
    ref="testCascader"
    @change="sendValue"
  ></el-cascader>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "MyCascader",
  data() {
    return {};
  },

  components: {},

  mounted() {},
  methods: {
    sendValue(value) {
      let obj = {
        name: this.data.__vModel__,
        value: value,
      };
      this.$emit("value", obj);
    },
  },
};
</script>

<style scoped>
.el-cascader-menu .el-scrollbar__wrap {
  min-height: 204px !important;
  max-height: 300px !important;
}
</style>
