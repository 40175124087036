
<template>
  <div class="dataLoad-warp">{{ data.__config__.label }}</div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.dataLoad-warp {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #999;
  height: 34px;
  line-height: 32px;
  border-radius: 4px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  border: 1px dashed #e0e0e0;
}
</style>
