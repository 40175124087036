<template>
  <div class="perspective-tree-view">
    <div class="tree-li" v-for="(item, index) in treeData" :key="index">
      <div class="tree-li-name">{{ item.name }}</div>
      <div v-if="item.data">
        <div
          v-for="(item2, index2) in item.data"
          :key="index2"
          class="tree-li-data"
        >
          {{ item2 ? item2 : "-" }}
        </div>
        <div class="tree-li-data">{{ item.sum }}</div>
      </div>
      <div v-if="item.children && item.children.length > 0">
        <perspectiveTree :treeData="item.children"></perspectiveTree>
      </div>
    </div>
  </div>
</template>
<script>
import perspectiveTree from "./perspectiveTree";
export default {
  name: "perspectiveTree",
  data() {
    return {};
  },
  components: { perspectiveTree },
  props: ["treeData"],
  methods: {},
  mounted() {
    console.log(this.treeData);
  },
};
</script>
<style scoped lang="scss">
.perspective-tree-view {
  display: flex;
}
.tree-li-name,
.tree-li-data {
  background: #f5f6f8;
  color: #000;
  font-size: 13px;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  text-align: left;
  border-bottom: 1px solid #eee;
  min-width: 100px;
  box-sizing: border-box;
  border-right: 1px solid #eee;
}
.tree-li-data {
  background: #fff;
}
</style>
