<template>
  <div class="link-dialog">
    <el-drawer
      title="快捷入口"
      :visible.sync="openDialog"
      direction="btt"
      size="90%"
      :before-close="handleClose"
    >
      <div class="body-box">
        <div class="look-box">
          <linkGrid :datas="datas" :noglink="noglink"></linkGrid>
        </div>
        <div class="set-box">
          <div class="set-item">
            <div class="lable">标题</div>
            <el-input
              style="width: 100%"
              v-model="datas.data.title"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="set-item">
            <div class="lable">入口样式</div>
            <el-radio-group v-model="datas.data.list_type">
              <el-radio label="list">列表</el-radio>
              <el-radio label="card">卡片</el-radio>
            </el-radio-group>
          </div>
          <div class="set-item">
            <div class="lable">入口列表</div>
            <div class="list-but" @click="openlistDialog">+ 添加入口</div>
            <div
              class="checked-box content-li islist"
              v-for="(item, index) in datas.data.list"
              :key="index"
            >
              <div class="app-icon" :style="{ background: item.color }">
                <i :class="'iconfont-1 ' + item.icon" v-if="item.icon"></i>
                <i :class="'iconfont-1 icon-shuju5'" v-else></i>
              </div>
              <div>{{ item.name }}</div>
              <span @click="deletes(item._id)" class="show">
                <svg
                  t="1712819249954"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="962"
                  width="20"
                  height="20"
                >
                  <path
                    d="M511.993349 831.827189c11.780301 0 21.324678-9.544377 21.324678-21.312398L533.318026 362.740558c0-11.781324-9.544377-21.323655-21.324678-21.323655-11.768021 0-21.312398 9.54233-21.312398 21.323655l0 447.774233C490.68095 822.282812 500.225327 831.827189 511.993349 831.827189zM405.386332 831.827189c11.780301 0 21.323655-9.544377 21.323655-21.312398L405.386332 362.740558c0-11.781324-9.543354-21.323655-21.323655-21.323655-11.769045 0-21.323655 9.54233-21.323655 21.323655l21.323655 447.774233C384.062678 822.282812 393.607054 831.827189 405.386332 831.827189zM895.797641 213.485209 703.894983 213.485209l0-63.970964c0-47.099714-38.183647-85.284385-85.294618-85.284385L405.386332 64.22986c-47.099714 0-85.294618 38.183647-85.294618 85.284385l0 63.970964L128.190079 213.485209c-11.769045 0-21.312398 9.543354-21.312398 21.324678 0 11.769045 9.543354 21.322631 21.312398 21.322631l89.422635 0 59.842946 618.34198c0 47.109947 38.182624 85.294618 85.283362 85.294618l298.508651 0c47.110971 0 85.295642-38.183647 85.295642-85.294618l59.832713-618.34198 89.421612 0c11.779278 0 21.323655-9.553587 21.323655-21.322631C917.120272 223.028563 907.575896 213.485209 895.797641 213.485209zM362.740046 149.515269c0-23.550369 19.097964-42.636053 42.647309-42.636053l213.214033 0c23.559579 0 42.647309 19.085684 42.647309 42.636053l0 63.970964-298.508651 0L362.740046 149.515269zM703.894983 874.474498c0 23.551392-19.085684 42.647309-42.647309 42.647309l-298.508651 0c-23.549346 0-42.647309-19.095917-42.647309-42.647309l-59.833737-618.34198 503.479953 0L703.894983 874.474498zM618.601388 831.827189c11.780301 0 21.334911-9.544377 21.334911-21.312398l21.312398-447.774233c0-11.781324-9.532097-21.323655-21.312398-21.323655-11.781324 0-21.334911 9.54233-21.334911 21.323655l-21.312398 447.774233C597.28899 822.282812 606.832344 831.827189 618.601388 831.827189z"
                    fill="#fb1a17"
                    p-id="963"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      :visible.sync="openListDialog"
      width="40%"
      title="提示"
      :modal="false"
      :before-close="listHandleClose"
    >
      <el-input
        style="margin-bottom: 15px"
        placeholder="请输入名称"
        prefix-icon="el-icon-search"
        v-model="search"
        @change="searchFun"
      ></el-input>
      <div class="dialog-cont" v-show="!issearch">
        <el-tree
          :data="appList"
          :props="props"
          :default-checked-keys="nowCheckData.checkedlist"
          :default-expanded-keys="nowCheckData.openlist"
          ref="tree"
          lazy
          :load="loadNode"
          show-checkbox
          node-key="_id"
          check-strictly
          @check-change="nowCheckFun"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="tree-list-box">
              <div class="app-icon" :style="{ background: data.color }">
                <i :class="'iconfont-1 ' + data.icon" v-if="data.icon"></i>
                <i :class="'iconfont-1 icon-shuju5'" v-else></i>
              </div>
              <span class="name-box">{{ data.name }}</span>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="dialog-cont" v-show="issearch">
        <div v-if="app_rows.length > 0">
          <div class="lable">应用</div>
          <div
            class="checked-box content-li islist"
            v-for="(item, index) in app_rows"
            :key="index"
          >
            <div class="app-icon" :style="{ background: item.color }">
              <i :class="'iconfont-1 ' + item.icon" v-if="item.icon"></i>
              <i :class="'iconfont-1 icon-shuju5'" v-else></i>
            </div>
            <div>{{ item.name }}</div>
            <el-checkbox
              v-model="item.checked"
              @change="searchCheckFun(item)"
            ></el-checkbox>
          </div>
        </div>
        <div v-if="form_rows.length > 0">
          <div class="lable">表单/仪表盘</div>
          <div
            class="checked-box content-li islist"
            v-for="(item, index) in form_rows"
            :key="index"
          >
            <div class="app-icon" :style="{ background: item.color }">
              <i :class="'iconfont-1 ' + item.icon" v-if="item.icon"></i>
              <i :class="'iconfont-1 icon-shuju5'" v-else></i>
            </div>
            <div>{{ item.name }}</div>
            <el-checkbox
              v-model="item.checked"
              @change="searchCheckFun(item)"
            ></el-checkbox>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="listHandleClose">取 消</el-button>
        <el-button type="primary" @click="setList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import linkGrid from "./linkGrid";
import {
  getAppList,
  getAppFormList,
  getAllAppForm,
  getAppFrom,
} from "@/api/app";

export default {
  props: {
    datas: Object,
    openDialog: false,
    noglink: true,
  },
  data() {
    return {
      openListDialog: false,
      appList: [],
      potList: [],
      nowCheckData: {
        list: [],
        openlist: [],
        checkedlist: [],
      },
      props: {
        label: "name",
        children: "children",
        isLeaf: "leaf",
      },
      search: "",
      issearch: false,
      app_rows: [],
      form_rows: [],
    };
  },
  components: {
    linkGrid,
  },
  watch: {
    openListDialog() {
      if (this.openListDialog) {
        this.nowCheckData.list = cloneDeep(this.datas.data.list);
        this.nowCheckData.checkedlist = cloneDeep(this.datas.data.checkedlist);
        this.nowCheckData.openlist = cloneDeep(this.datas.data.openlist);
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    searchFun() {
      if (this.search != "") {
        this.issearch = true;
        getAllAppForm(this.search).then((res) => {
          this.app_rows = this.setChecked(res.data.app_rows);
          this.form_rows = this.setChecked(res.data.form_rows);
        });
      } else {
        this.issearch = false;
      }
    },
    setChecked(data) {
      data.forEach((item) => {
        let foundObject = this.nowCheckData.checkedlist.some(
          (obj) => obj == item._id
        );
        if (foundObject) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      return data;
    },
    searchCheckFun(data) {
      if (data.checked) {
        this.nowCheckData.checkedlist.push(data._id);
        this.$refs.tree.setCheckedKeys(this.nowCheckData.checkedlist);
        this.nowCheckData.list.push(data);
        if (data.app_id) {
          this.nowCheckData.openlist.push(data.app_id);
        }
      } else {
        const index = this.nowCheckData.checkedlist.findIndex(
          (item) => item === data._id
        );
        if (index !== -1) {
          this.nowCheckData.checkedlist.splice(index, 1);
          this.nowCheckData.list.splice(index, 1);
          this.$refs.tree.setCheckedKeys(this.nowCheckData.checkedlist);
        }
      }
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
    openlistDialog() {
      getAppList(this.name).then((res) => {
        this.potList = res.data.no_app_ids;
        res.data.rows.forEach((item) => {
          item.isLeaf = "leaf";
          item.judge = this.arrayContainsId(item._id);
        });
        this.appList = res.data.rows;
      });
      this.openListDialog = true;
    },
    arrayContainsId(id) {
      return this.potList.some((item) => item === id);
    },
    listHandleClose() {
      this.openListDialog = false;
    },
    loadNode(node, resolve) {
      if (node.data._id) {
        getAppFrom(node.data._id).then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            res.data.forEach((item) => {
              item.leaf = true;
              item.parent = node.data;
              item.parent.judge = this.arrayContainsId(node.data._id);
            });
            this.appList.forEach((item) => {
              if (item._id == node.data._id) {
                item.children = JSON.stringify(res.data);
                resolve(res.data);
              }
            });
          } else {
            resolve([]);
          }
        });
      }
    },
    nowCheckFun() {
      let list = this.$refs.tree.getCheckedNodes();
      let checked = this.$refs.tree.getCheckedKeys();
      let open = [];
      this.appList.forEach((item) => {
        if (item.children) {
          let child = JSON.parse(item.children);
          for (let i = 0; i < child.length; i++) {
            let foundObject = list.some((obj) => obj._id == child[i]._id);
            if (foundObject) {
              open.push(item._id);
              break;
            }
          }
        }
      });
      this.nowCheckData.list = list;
      this.nowCheckData.checkedlist = checked;
      this.nowCheckData.openlist = open;
    },
    setList() {
      this.datas.data.list = cloneDeep(this.nowCheckData.list);
      this.datas.data.checkedlist = cloneDeep(this.nowCheckData.checkedlist);
      this.datas.data.openlist = cloneDeep(this.nowCheckData.openlist);
      this.listHandleClose();
    },
    // 删除
    deletes(val) {
      this.datas.data.list.forEach((item, index) => {
        if (item._id == val) {
          this.datas.data.list.splice(index, 1);
        }
      });
    },
    nowCheckFun() {
      let list = this.$refs.tree.getCheckedNodes();
      let checked = this.$refs.tree.getCheckedKeys();
      let open = [];
      this.appList.forEach((item) => {
        if (item.children) {
          let child = JSON.parse(item.children);
          for (let i = 0; i < child.length; i++) {
            let foundObject = list.some((obj) => obj._id == child[i]._id);
            if (foundObject) {
              open.push(item._id);
              break;
            }
          }
        }
      });
      this.nowCheckData.list = list;
      this.nowCheckData.checkedlist = checked;
      this.nowCheckData.openlist = open;
    },
    setList() {
      this.datas.data.list = cloneDeep(this.nowCheckData.list);
      this.datas.data.checkedlist = cloneDeep(this.nowCheckData.checkedlist);
      this.datas.data.openlist = cloneDeep(this.nowCheckData.openlist);
      this.listHandleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.link-dialog ::v-deep .el-dialog__header {
  display: block;
}
::v-deep .el-dialog__wrapper {
  z-index: 3020 !important;
}

::v-deep .el-drawer__header {
  text-align: center;
  font-weight: bold;
  color: #303133;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e7ed;
}

::v-deep .custom-tree-node {
  width: calc(100% - 50px);
}

::v-deep .el-checkbox {
  order: 2;
}

.app-icon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  background: rgb(255, 164, 47);

  border-radius: 4px;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  color: #fff;
}

.body-box {
  display: flex;
  height: 100%;
  .look-box {
    padding: 24px;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid #e4e7ed;
    box-sizing: border-box;
  }

  .set-box {
    width: 360px;
    height: 100%;
    overflow-y: auto;
    padding: 24px;
    box-sizing: border-box;
    .set-item {
      margin-bottom: 20px;
    }
  }

  .list-but {
    color: #409eff;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.tree-list-box {
  display: flex;
  align-items: center;

  .name-box {
    flex: 1;
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
  }
}

.dialog-cont {
  height: 50vh;
  overflow-y: auto;

  ::v-deep .el-tree > .el-tree-node {
    padding-left: 0;
  }
}

.content-li.islist {
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  .app-icon {
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin-right: 10px;
    i {
      font-size: 18px;
    }
  }

  & > div:nth-child(2) {
    flex: 1;
    margin: 0;
    text-align: left;
    display: -webkit-box;
    /* 将元素设置为弹性容器 */
    -webkit-line-clamp: 2;
    /* 指定最多显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置垂直布局 */
    overflow: hidden;
  }
}
</style>
