<template>
  <div class="piechart-warp">
    <div class="piechart-count" v-if="type !== '4'" style="height: 100%">
      <div
        :id="id"
        :style="{
          height: '100%',
          background:
            option.cssStyle.bgType === '1'
              ? option.cssStyle.bgColor
              : `url(${option.cssStyle.bgUrl}) `,
        }"
      ></div>
    </div>

    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",

      chartType: "1",
      id: "piechart",
    };
  },
  components: {},

  props: ["option", "chartData", "defaultColor", "colorList", "chartId"],

  watch: {
    option: {
      handler(val) {
        console.log(val);

        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    colorList: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    type(val) {},
  },
  methods: {
    getList(data, chartData) {
      let len = data.draggableObj.dimensionList.length;
      let len2 = data.draggableObj.targatList.length;
      if (data.collapseActive) {
        if (data.collapseActive.title === "实心") {
          this.chartType = "1";
        } else if (data.collapseActive.title === "粗圆环") {
          this.chartType = "2";
        } else if (data.collapseActive.title === "细圆环") {
          this.chartType = "3";
        }
      }
      if (len === 1 && len2 === 1) {
        this.type = "3";
        let list = [];
        chartData[0].xAxis.forEach((item) => {
          list.push(item);
        });

        this.$nextTick(() => {
          this.initChart(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else {
        this.type = "4";
      }
    },
    initChart(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var option;

      if (myChart) {
        myChart.clear();
      }
      let that = this;
      let radius = "50%";
      if (this.chartType === "1") {
        radius = "50%";
      } else if (this.chartType === "2") {
        radius = ["20%", "50%"];
      } else if (this.chartType === "3") {
        radius = ["30%", "50%"];
      }

      let dataList = [];
      let formatterB = data.pieSetting.showDimension ? "{b}" : "";
      let formatterC = data.pieSetting.showTargat ? "{c}" : "";
      let formatterD = data.pieSetting.showPercent ? "({d})%" : "";
      let formatterData = formatterB + " " + formatterC + " " + formatterD;

      chartData[0].xAxis.forEach((item, index) => {
        dataList.push({
          value: chartData[0].yAxis[index],
          name: item,
        });
      });
      option = {
        tooltip: {
          trigger: "item",
          formatter: formatterData, //自定义显示格式(b:name, c:value, d:百分比)
        },
        legend: {
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },

        series: [
          {
            type: "pie",
            radius: radius,
            data: dataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: data.yAxis.showData,
                lineHeight: 10,
                // position: 'inside',
                formatter: formatterData, //自定义显示格式(b:name, c:value, d:百分比)
                fontSize: 12, // 字体大小
              },
              labelLine: {
                normal: {
                  show: data.yAxis.showData,
                },
              },
            },

            itemStyle: {
              normal: {
                color: function (data) {
                  if (that.colorList[data.dataIndex]) {
                    return that.colorList[data.dataIndex].color;
                  }
                },
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
  created() {
    console.log(this.chartId, "获取图表id2222");
  },
  mounted() {
    if (this.chartId) {
      this.id = this.chartId;
    }
    this.getList(this.option, this.chartData);
  },
};
</script>

<style lang="scss" scoped>
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.piechart-warp {
  position: relative;
  height: 100%;
  width: 100%;

  #piechart {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-attachment: fixed;
  }
}
</style>
