import {
  Promise
} from "core-js";
import {
  getUserLogin
} from "@/api/user";
const state = {
  token: localStorage.getItem("Token") || "",
  PermissionsList: JSON.parse(localStorage.getItem("PList")) || {},
  JurisdictionList: JSON.parse(localStorage.getItem("JList")) || {},
  listType: localStorage.getItem("listTypes") || null,
  HomebacklogTotal: 0,
  AppJurisdiction: localStorage.getItem("AppJurisdiction") || false,
  wechatLg: true,
};
const mutations = {
  setTokenMutation: (state, payload) => {
    state.token = payload.access_token;
    localStorage.setItem("Token", payload.access_token);
  },
  setPermissionsList: (state, payload) => {
    state.PermissionsList = payload;
    localStorage.setItem("PList", JSON.stringify(payload));
  },
  setJurisdictionList: (state, payload) => {
    state.JurisdictionList = payload;
    localStorage.setItem("JList", JSON.stringify(payload));
  },
  setCommissionList(state, payload) {
    state.listType = payload;
    localStorage.setItem("listTypes", payload);
  },
  setHomebacklogTotal(state, payload) {
    state.HomebacklogTotal = payload;
  },
  setAppJurisdiction(state, payload) {
    state.AppJurisdiction = payload;
    localStorage.setItem("AppJurisdiction", payload);
    console.log(state.AppJurisdiction, "修改data");
  },
  setWechat(state, payload) {
    state.wechatLg = payload;
  },
  setWechatLg(state, payload) {
    state.wechatLg = payload;
  },
};

const actions = {
  loginAction({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      getUserLogin(payload)
        .then((res) => {
          commit("setTokenMutation", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  ddlogoutAction({
    commit
  }, payload) {
    commit("setTokenMutation", payload);
  },
  logoutAction({
    commit
  }, payload) {
    commit("setTokenMutation", "");
    commit("setWechat", false);
  },
  actionSetWeChatLg({
    commit
  }, payload) {
    commit("setWechatLg", false);
  },
  havePermissionsList({
    commit
  }, list) {
    commit("setPermissionsList", list);
  },
  haveJurisdictionList({
    commit
  }, list) {
    commit("setJurisdictionList", list);
  },
  haveCommission({
    commit
  }, list) {
    commit("setCommissionList", list);
  },
  haveHomebacklogTotal({
    commit
  }, list) {
    commit("setHomebacklogTotal", list);
  },
  haveHomebacklogTotal({
    commit
  }, list) {
    commit("setAppJurisdiction", list);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
