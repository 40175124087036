var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-form",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"content-info"},[_c('div',{staticClass:"form-ul"},_vm._l((_vm.fileds),function(item,index){return (item.look)?_c('div',{key:index,staticClass:"form-li-warp",style:({
              width:
                item.tagIcon === 'row'
                  ? '100%'
                  : item.tagIcon === 'table'
                  ? '100%'
                  : '50%',
            })},[(item.tag === 'el-upload')?_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.label))]),_vm._l((_vm.getValue(item)),function(fileItem,fileIndex){return _c('div',{key:fileItem.uid},[_c('div',{staticClass:"li-value"},[_c('div',{staticClass:"file-list"},[_c('div',[_vm._v(_vm._s(fileItem.name))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.down(fileItem)}}},[_vm._v("查看下载")])],1)])])})],2):(item.tag === 'el-editor')?_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"li-value",domProps:{"innerHTML":_vm._s(_vm.getValue(item))}})]):(item.tagIcon === 'row')?_c('div',{staticClass:"form-li",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(_vm._s(item.label))]),_c('el-table',{staticStyle:{"width":"100%","height":"100%"},attrs:{"data":_vm.getValue(item),"max-height":"500","header-cell-style":{
                  background: '#F0F4FA',
                  color: '#303133',
                  borderRadius: '4px',
                }}},[_c('el-table-column',{attrs:{"type":"index","width":"55","label":"序号","align":"center"}}),_vm._l((item.child),function(itemChild,indexChild){return (itemChild.look)?_c('el-table-column',{key:indexChild,attrs:{"label":itemChild.label,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(itemChild.tag !== 'el-upload')?_c('div',{staticClass:"row-class"},[(itemChild.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsTabelValue(
                              scope.row,
                              itemChild,
                              scope.$index
                            )}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabsTabelValue( scope.row, itemChild, scope.$index ))+" ")])]):_c('div',{staticClass:"upload-list"},_vm._l((_vm.getTabsTabelValue(
                          scope.row,
                          itemChild,
                          scope.$index
                        )),function(uploadItem,uploadIndex){return _c('div',{key:uploadIndex,staticStyle:{"color":"#409eff","cursor":"pointer"},on:{"click":function($event){return _vm.fileDown(uploadItem)}}},[_vm._v(" "+_vm._s(uploadItem.name)+" ")])}),0)]}}],null,true)}):_vm._e()})],2)],1):(item.tagIcon === 'table')?_c('div',{staticClass:"form-li",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_vm._v(_vm._s(item.label))]),_c('el-tabs',{staticStyle:{"width":"100%"},attrs:{"type":"border-card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.getTabs(item)),function(itemTab,indexTab){return _c('el-tab-pane',{key:indexTab,attrs:{"label":itemTab.name,"name":itemTab.name}},[_c('div',{staticClass:"tabs"},_vm._l((itemTab.child),function(itemChild,indexChild){return (itemChild.look)?_c('div',{key:indexChild,staticClass:"tabs-ul",style:({
                        width:
                          itemChild.tagIcon === 'row'
                            ? '100%'
                            : itemChild.tagIcon === 'table'
                            ? '100%'
                            : '50%',
                      })},[(itemChild.tag === 'el-upload')?_c('div',{staticClass:"tabs-li"},[_c('div',{staticClass:"li-title"},[_vm._v(_vm._s(itemChild.label))]),_vm._l((_vm.getTabsValue(
                            itemChild
                          )),function(uploadItem2,uploadIndex2){return _c('div',{key:uploadIndex2,staticClass:"li-upload-text"},[_c('div',[_vm._v(_vm._s(uploadItem2.name))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.down(uploadItem2)}}},[_vm._v("查看下载")])],1)})],2):(itemChild.tagIcon === 'Editor')?_c('div',{staticClass:"tabs-li"},[_c('div',[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"li-value",domProps:{"innerHTML":_vm._s(_vm.getTabsValue(itemChild))}})]):(itemChild.tagIcon === 'row')?_c('div',{staticClass:"tabs-li"},[_c('el-table',{attrs:{"data":_vm.getTabsValue(itemChild),"max-height":"500","header-cell-style":{
                            background: '#F0F4FA',
                            color: '#303133',
                            borderRadius: '4px',
                          }}},[_c('el-table-column',{attrs:{"type":"index","width":"55","label":"序号","align":"center"}}),_vm._l((itemChild.child),function(tableColumn,indexColumn){return (tableColumn.look)?_c('el-table-column',{key:indexColumn,attrs:{"label":tableColumn.label,"prop":tableColumn.fileds,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(tableColumn.tag !== 'el-upload')?_c('div',{staticClass:"row-class"},[(tableColumn.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsTabelValue(
                                        scope.row,
                                        tableColumn,
                                        scope.$index
                                      )}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabsTabelValue( scope.row, tableColumn, scope.$index ))+" ")])]):_c('div',{staticClass:"upload-list"},_vm._l((_vm.getTabsTabelValue(
                                    scope.row,
                                    tableColumn,
                                    scope.$index
                                  )),function(uploadItem,uploadIndex){return _c('div',{key:uploadIndex,staticStyle:{"color":"#409eff","cursor":"pointer"},on:{"click":function($event){return _vm.fileDown(uploadItem)}}},[_vm._v(" "+_vm._s(uploadItem.name)+" ")])}),0)]}}],null,true)}):_vm._e()})],2)],1):_c('div',{staticClass:"tabs-li"},[_c('div',{staticClass:"li-title"},[_vm._v(_vm._s(itemChild.label))]),_c('div',{staticClass:"li-text"},[(itemChild.tagIcon === 'Sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsValue(itemChild)}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabsValue(itemChild))+" ")])])])]):_vm._e()}),0)])}),1),_c('div')],1):_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"li-value"},[(item.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getValue(item)}})]):_c('div',[_vm._v(_vm._s(_vm.getValue(item)))])])])]):_vm._e()}),0)])]),_c('div',{staticClass:"print-btn"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.printSubmit}},[_vm._v("打印")])],1)]),_c('iframe',{staticStyle:{"display":"none"},attrs:{"src":_vm.pdfSrc}})])
}
var staticRenderFns = []

export { render, staticRenderFns }