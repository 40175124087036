<template>
  <div class="circular-warp">
    <div class="circular-content" v-if="type !== '4'" style="height: 100%">
      <div
        :id="id"
        :style="{
          height: '100%',
          background:
            option.cssStyle.bgType === '1'
              ? option.cssStyle.bgColor
              : `url(${option.cssStyle.bgUrl}) `,
        }"
      ></div>
    </div>
    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>
<script>
import { getChartsSetting } from "@/api/charts";
export default {
  data() {
    return {
      chartType: "1",
      type: "3",
      value: 87,
      tValue: 0,
      gValue: 0,
      id: "circular",
    };
  },
  created() {},
  props: ["option", "chartId", "chartData", "defaultColor", "colorList"],
  watch: {
    option: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 100);
      },
      deep: true,
    },
    colorList: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 100);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.chartId) {
      this.id = this.chartId;
    }
    this.getList(this.option, this.chartData);
  },
  methods: {
    getList(data, chartData) {
      let len = data.draggableObj.targatList.length;
      let len2 = data.draggableObj.goalValueList.length;
      let title = data.collapseActive.title;
      this.tValue = chartData[0].yAxis[0];
      this.gValue = Number(chartData[0].goal_num);
      this.value = (this.tValue / this.gValue) * 100;
      if (len === 1 && len2 === 1 && title === "环形进度图") {
        this.type = "1";
        this.$nextTick(() => {
          this.initChart();
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
          // this.initChart3();
        });
      } else if (len === 1 && len2 === 1 && title === "半环形进度图") {
        this.type = "2";
        this.$nextTick(() => {
          this.initChart2();
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 1 && len2 === 1 && title === "水位图") {
        this.type = "3";
        this.$nextTick(() => {
          this.initChart3();
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else {
        this.type = "4";
      }
    },
    initChart() {
      var myChart = this.$echarts.init(document.getElementById(this.id));

      let value = this.value.toFixed(2);
      var option;
      if (myChart) {
        myChart.clear();
      }
      // 指定图表的配置项和数据
      var option = {
        title: [
          {
            text: "已完成进度",
            left: "center",
            top: "55%",
            textStyle: { color: "#A9AFBB", fontWeight: "normal" },
          },
        ],
        series: {
          name: "进度",
          type: "pie", //pie类型的图实现环形图
          radius: ["90%", "100%"], //数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
          center: ["50%", "50%"], //圆心坐标
          avoidLabelOverlap: false, //是否启用防止标签重叠策略
          startAngle: 270, //第一个数据开始绘制的角度，以正交直角坐标系为标准
          label: {
            //每个数据的标签
            show: true, //设置为true则显示第一个数据
            position: "center", //位置居中
            formatter: value + "%",
            fontSize: 40,
            fontWeight: "bold",
          },
          color: [
            this.colorList[0] ? this.colorList[0].color : this.defaultColor[0],
            this.colorList[1] ? this.colorList[1].color : "#f0f6fa",
          ],
          labelLine: {
            show: true,
          },
          data: [
            { value: value, name: "已完成" },
            {
              value: 100 - value,
              name: "未完成",
            },
          ],
        },
      };
      myChart.setOption(option);
    },
    initChart2() {
      // 进度百分比
      var value = this.value.toFixed(2);
      var myChart = this.$echarts.init(document.getElementById(this.id));
      if (myChart) {
        myChart.clear();
      }
      var option = {
        title: [
          //标题组件，数组里的一个对象表示一个标题组件
          {
            text: "已完成进度",
            left: "center",
            top: "55%",
            textStyle: { color: "#A9AFBB", fontWeight: "normal" },
          },
        ],
        series: [
          {
            name: "Access From",
            type: "pie",
            color: [
              this.colorList[0]
                ? this.colorList[0].color
                : this.defaultColor[0],
              this.colorList[1] ? this.colorList[1].color : "#f0f6fa",
            ],
            radius: ["90%", "100%"],
            center: ["50%", "70%"],
            startAngle: 180,
            endAngle: 360,
            data: [
              { value: value, name: "已完成" },
              {
                value: 100 - value,
                name: "未完成",
              },
            ],
            labelLine: {
              //删除指示线
              show: false,
            },
            label: {
              // 添加标签显示进度百分比
              show: true,
              position: "center", // 标签位置为内部
              formatter: value + "%", // 格式化显示百分比
              fontSize: 40,
              fontWeight: "bold",
            },
          },
        ],
      };
      myChart.setOption(option);
    },

    initChart3() {
      var myChart = this.$echarts.init(document.getElementById(this.id));
      // let value = this.value.toFixed(2)
      let value = this.value.toFixed(2);
      const option = {
        title: [
          //标题组件，数组里的一个对象表示一个标题组件
          {
            text: "已完成进度",
            textStyle: { color: "#000", fontWeight: "normal" },
          },
        ],
        series: [
          {
            type: "liquidFill",
            data: [{ value: value / 100 }],
            animation: false,
            color: [
              this.colorList[0]
                ? this.colorList[0].color
                : this.defaultColor[0],
              this.colorList[1] ? this.colorList[1].color : "#f0f6fa",
            ],
            radius: "90%",
            center: ["50%", "50%"],
            outline: {
              show: false,
            },
            backgroundStyle: {
              color: this.colorList[1] ? this.colorList[1].color : "#f0f6fa",
            },
            label: {
              fontSize: 40,
              fontWeight: "500",
              color: "#000",
              insideColor: "#000",
              position: ["50%", "50%"],
              formatter: function (params) {
                return value + "%";
              },
            },
            itemStyle: {
              shadowBlur: 0, // 阴影模糊大小
              shadowColor: "rgba(0, 0, 0, 0)", // 阴影颜色
              shadowOffsetX: 0, // 阴影 X 轴偏移
              shadowOffsetY: 0, // 阴影 Y 轴偏移
            },
            outline: {
              show: true,
              borderDistance: 0, // 边框距离水球的距离
              itemStyle: {
                borderWidth: 2, // 边框宽度
                borderColor: "#409efd", // 边框颜色，这里设置为蓝色
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circular-content {
  height: 100%;
}

.circular-warp {
  position: relative;
  height: 100%;
  width: 100%;

  #circular {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-attachment: fixed;
  }
}
</style>
