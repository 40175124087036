var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hstogram-warp"},[(_vm.type !== '4')?_c('div',{staticClass:"hstogram-count",staticStyle:{"height":"100%"}},[_c('div',{style:({
        height: '100%',
        background:
          _vm.option.cssStyle.bgType === '1'
            ? _vm.option.cssStyle.bgColor
            : `url(${_vm.option.cssStyle.bgUrl}) `,
      }),attrs:{"id":_vm.id}})]):_c('div',{staticClass:"empty"},[_vm._v("拖拽左侧字段到上方指标、目标值栏来添加数据")])])
}
var staticRenderFns = []

export { render, staticRenderFns }