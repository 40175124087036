<!--  -->
<template>
  <div :style="{ pointerEvents: data.disabled ? 'none' : 'unset' }">
    <div :style="{ backgroundColor: data.disabled ? '#F5F7FA' : '#fff' }" class="depart-check"
      v-if="data.__config__.defaultValue.length === 0" @click="selectDepart">
      {{ data.placeholder }}
    </div>
    <div class="depart-check" v-else @click="selectDepart"
      :style="{ backgroundColor: data.disabled ? '#F5F7FA' : '#fff' }">
      <div v-for="(item, index) in data.__config__.defaultValue" :key="index" class="depart-list">
        <div>{{ item.label }}</div>
      </div>
    </div>

    <departSelect :dialogVisible="selectDialog" @handleSelectClose="handleSelectClose" :title="title" :type="type"
      :activeData="data" :selectRange="data.isSelectRange" @submit="selectSubmit"></departSelect>
  </div>
</template>

<script>
import departSelect from "@/components/departSelect/index.vue";
export default {
  data () {
    return {
      title: "选择部门",
      echoData: [],
      type: "depart",
      selectDialog: false,
      showTrends: false,
    };
  },
  components: {
    departSelect,
  },
  mounted () {
    this.data.__config__.defaultValue =
      this.data.__config__.defaultValue.reduce((accumulator, current) => {
        if (!accumulator[current.id]) {
          accumulator[current.id] = true;
          accumulator.push(current);
        }
        return accumulator;
      }, []);
  },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {},
  methods: {
    selectDepart () {
      this.selectDialog = true;
    },
    handleSelectClose () {
      this.selectDialog = false;
    },
    sendValue () {
      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
    treeDataChange (data) {
      data.forEach((item) => {
        this.$set(item, "id", item._id);
        this.$set(item, "label", item.name);
        delete item._id;
        delete item.name;
        if (item.children) {
          this.treeDataChange(item.children);
        }
      });
      return data;
    },
    selectSubmit (list, trendsList, showTrends) {
      let arr = list;
      arr.push(...trendsList);
      const idSet = new Set();
      arr = arr.filter((item) => {
        const isUnique = !idSet.has(item.id);
        idSet.add(item.id);
        return isUnique;
      });
      console.log(arr);
      this.$set(this.data.__config__, "defaultValue", arr);
      this.showTrends = showTrends;

      this.selectDialog = false;
      this.sendValue();
    },
  },
  created () {
    this.showTrends = this.data.showTrends;

    if (
      (this.data.modelType === "custom" && this.data.trendsChecked) ||
      this.data.modelType === "all"
    ) {
      let arr = JSON.parse(
        JSON.stringify(this.$store.state.user.PermissionsList.department)
      );
      arr = this.treeDataChange(arr);

      if (this.data.showTrends) {
        arr.forEach((item) => {
          this.data.__config__.defaultValue.push(item);
        });
      }
    }
  },
  mounted () {

  },
};
</script>
<style lang='scss' scoped>
::v-deep .menber-tree .el-checkbox {
  position: absolute;
  right: 0;
  top: 8px;
}

.depart-check {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 14px;

  text-indent: 10px;
  font-weight: 400;
  cursor: pointer;
  color: #c0c4cc;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 34px;
}

.depart-list {
  div {
    margin: 0px 5px 5px 5px;
    color: #409eff;
  }
}
</style>
