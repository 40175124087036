<!-- 明细表 -->
<template>
  <div class="echarts-warp">
    <div class="title" :style="{ color: settingOption.cssStyle.titleColor }">
      {{ settingOption.title }}
    </div>
    <div v-if="rowData.length > 0">
      <div class="line"></div>
      <div class="form-content">
        <el-table
          :data="rowData"
          border
          style="width: 100%"
          :header-cell-style="{
            height: '100%',
            color: settingOption.cssStyle.headerTableColor,
            background:
              settingOption.cssStyle.bgType === '1'
                ? settingOption.cssStyle.bgColor
                : `url(${settingOption.cssStyle.bgUrl})`,
            textAlign: settingOption.cssStyle.alignment,
          }"
          :cell-style="{
            height: '100%',
            color: settingOption.cssStyle.contentColor,
            background:
              settingOption.cssStyle.bgType === '1'
                ? settingOption.cssStyle.bgColor
                : `url(${settingOption.cssStyle.bgUrl})`,
            textAlign: settingOption.cssStyle.alignment,
          }"
        >
          <el-table-column
            type="index"
            width="50"
            v-if="settingOption.diaplayNumber == true"
            :algni="'center'"
          />
          <el-table-column
            v-for="(item, index) in tableData"
            :key="index"
            :prop="item.key"
            :label="item.label"
            width="180"
            :algni="'center'"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  item.tag === 'departCheck' ||
                  item.tag == 'menberCheck' ||
                  item.tag == 'menberSingle' ||
                  item.tag == 'departSingle'
                "
              >
                {{ getDict(scope.row[item.key], item.tag) }}
              </div>
              <div v-else>{{ rowData[scope.$index][item.key] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="query.pageSize"
          :layout="layout"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>
import { getdetailsTable } from "@/api/charts";
import { color } from "echarts";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      rowData: [],
      originalRowData: [],
      query: {
        pageSize: 10,
        pageNum: 1,
        user_name: "",
        type: 1,
      },
      total: 0,
      formData: {},
      app_id: "",
      form_id: "",

      background_color: "",
      titleColor: "",
      layout: "total, sizes, prev, pager, next, jumper",
    };
  },
  props: ["settingOption"],

  watch: {
    // "settingOption": {
    //   handler (val) {
    //     // console.log(1111)
    //     // this.query.pageNum = 1
    //   },
    //   deep: true
    // },
    "settingOption.draggableObj": {
      handler(val) {
        console.log(val);
        this.formData = val;
        this.query.pageNum = 1;
        let data = { ...this.formData, ...this.query };

        this.getList(data);
      },
      deep: true,
    },
    // "data_source_id": {
    //   handler (val) {
    //     this.formData.data_source_id = val
    //     this.getList(this.formData)
    //     this.rowData = []
    //     this.tableData = []
    //     this.total = 0

    //   }
    // },
    "settingOption.endValue": {
      handler(val) {
        this.$nextTick(() => {
          this.query.pageSize = val ? Number(val) : this.query.pageSize;
          this.query.pageNum = 1;
          let data = { ...this.formData, ...this.query };
          this.getList(data);
        });
      },
      deep: true,
    },
    "settingOption.displayEnd": {
      handler(val) {
        this.$nextTick(() => {
          if (val == false) {
            this.layout = "total, sizes, prev, pager, next, jumper";
            this.formData = this.settingOption.draggableObj;
            let data = { ...this.formData, ...this.query };
            this.getList(data);
          } else {
            this.layout = "total, prev, pager, next, jumper";
          }
        });
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.settingOption.draggableObj) {
        this.formData = this.settingOption.draggableObj;
        let data = { ...this.formData, ...this.query };
        this.getList(data);
      }
    }, 500);
  },
  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
  methods: {
    getMenberDict(data) {
      let arr = [];
      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.length === 0 ? "" : arr.join(",");
    },
    getDepartDict(data) {
      let arr = [];

      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.length === 0 ? "" : arr.join(",");
    },
    getDict(item, tag) {
      console.log(tag);
      let arr = [];
      if (Array.isArray(item)) {
        if (tag === "departCheck" || tag === "departSingle") {
          arr = this.getDepartDict(item);
        } else {
          arr = this.getMenberDict(item);
        }
      }
      return arr.length === 0 ? "" : arr;
    },
    getList(data) {
      if (data && data.dimensionList) {
        if (data.dimensionList.length > 0) {
          getdetailsTable(data).then((res) => {
            // this.rowData = (res.data.data).filter(item => item.vw1713149511875)
            this.rowData = res.data.data;
            this.tableData = res.data.filed_nam;
            console.log(this.rowData, this.tableData);
            if (this.settingOption.displayEnd) {
              if (this.settingOption.endValue) {
                if (this.settingOption.endValue > res.data.total) {
                  this.total = res.data.total;
                } else {
                  this.total = Number(this.settingOption.endValue);
                }
              } else {
                this.total = res.data.total;
              }
              this.dataTotal = res.data.total;
            } else {
              this.total = res.data.total;
            }
          });
        } else {
          this.rowData = [];
          this.tableData = [];
          this.total = 0;
        }
      } else {
        return;
      }
    },

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.query.pageNum = 1;
      let data = { ...this.formData, ...this.query };
      this.getList(data);
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      let data = { ...this.formData, ...this.query };
      this.getList(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts-warp {
  padding: 24px 24px 0 24px;
  height: 100%;
  box-sizing: border-box;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #303133;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .line {
    width: 100%;
    margin: 0 auto;
    height: 1px;
    background-color: #ebeef5;
  }

  .form-content {
    margin-top: 30px;

    ::v-deep .el-table__header-wrapper {
      border-bottom: 1px solid #ebeef5;
    }

    // ::v-deep .el-table__header-wrapper .el-table--border .el-table__cell .cell {
    //   color: #409EFF
    // }
    // .el-table thead th {
    //   color: #409EFF
    // }
  }

  // ::v-deep .el-table thead {
  //   color: #409EFF
  // }

  .pagination {
    text-align: right;
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
