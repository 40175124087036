<template>
  <div class="line-warp">
    <div class="line-count" v-if="type !== '4'" style="height: 100%">
      <div
        :id="id"
        :style="{
          height: '100%',
          background:
            option.cssStyle.bgType === '1'
              ? option.cssStyle.bgColor
              : `url(${option.cssStyle.bgUrl}) `,
        }"
      ></div>
    </div>

    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",

      chartType: "1",
      id: "line",
    };
  },
  components: {},
  mounted() {},
  props: ["option", "chartData", "defaultColor", "colorList", "chartId"],

  watch: {
    option: {
      handler(val) {
        if (val.collapseActive) {
          if (val.collapseActive.title === "普通柱形图") {
            this.chartType = "1";
          } else if (val.collapseActive.title === "堆积柱形图") {
            this.chartType = "2";
          } else if (val.collapseActive.title === "百分比堆积柱形图") {
            this.chartType = "3";
          }
        }

        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    colorList: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
    type(val) {},
  },
  methods: {
    getList(data, chartData) {
      let len = data.draggableObj.dimensionList.length;
      let len2 = data.draggableObj.targatList.length;

      if (len === 1 && len2 > 1) {
        this.type = "1";
        this.$nextTick(() => {
          this.initChart(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 2 && len2 === 1) {
        this.type = "2";
        this.$nextTick(() => {
          this.initChart2(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else if (len === 1 && len2 === 1) {
        this.type = "1";

        this.$nextTick(() => {
          this.initChart(data, chartData);
          const resizeOb = new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.$echarts.getInstanceByDom(entry.target).resize();
            }
          });
          resizeOb.observe(document.getElementById(this.id));
        });
      } else {
        this.type = "4";
      }
    },
    mergedArray(arrays) {
      if (arrays.length === 0) {
        return [];
      }

      return arrays.reduce((merged, array) => {
        array.forEach((value, index) => {
          if (merged[index] === undefined) {
            merged[index] = [value];
          } else {
            merged[index].push(value);
          }
        });
        return merged;
      }, []);
    },
    initChart(data, chartData) {
      var chartDom = document.getElementById(this.id);
      var myChart = this.$echarts.init(chartDom);
      var option;
      let markLineList = [];
      if (myChart) {
        myChart.clear();
      }
      let dataList = [];
      let legendList = [];
      let that = this;
      let stackPercentage = [];
      chartData.forEach((item) => {
        stackPercentage.push(item.yAxis);
      });
      stackPercentage = this.mergedArray(stackPercentage);
      data.yAxis.lines.forEach((item) => {
        let formatter = "";
        if (item.contentType === 1) {
          formatter = item.name;
        } else if (item.contentType === 2) {
          formatter = item.fixedValue;
        } else {
          formatter = `${item.name}: ${item.fixedValue}`;
        }
        markLineList.push({
          yAxis: item.fixedValue,
          label: {
            show: true,
            formatter: formatter,
            position: "start",
            distance: [-50, 0],
            color: item.color,
          },
          lineStyle: { normal: { color: item.color } },
        });
      });

      data.draggableObj.targatList.forEach((item, index) => {
        legendList.push(item.__config__.label);
        dataList.push({
          name: item.__config__.label,
          type: "line",
          stack:
            this.chartType === "1"
              ? ""
              : data.draggableObj.targatList[0].__config__.label,
          data: chartData[index].yAxis,
          color: this.colorList[index]
            ? this.colorList[index].color
            : this.defaultColor[index],
          markLine: {
            silent: true,
            symbol: "none",

            data: markLineList,
          },

          itemStyle: {
            normal: {
              label: {
                show: data.yAxis.showData,
                position: "top",
                color: "#6f6f6f",

                formatter: function (d) {
                  if (that.chartType === "3") {
                    let sumArray = stackPercentage[d.dataIndex].reduce(
                      (sum, num) => sum + num,
                      0
                    );

                    let count = ((d.data / sumArray) * 100).toFixed(2) + "%";
                    return count;
                  } else {
                    if (chartData[index].advanced === "proportion") {
                      let sumArray = chartData[index].yAxis.reduce(
                        (sum, num) => sum + num,
                        0
                      );
                      let count = ((d.data / sumArray) * 100).toFixed(2) + "%";
                      return count;
                    } else {
                      return d.data;
                    }
                  }
                },
              },
              color: function (data) {
                return data.color;
              },
            },
          },
        });
      });

      option = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let result = `${params[0].name}<br>`;
            params.forEach((item) => {
              if (item.value) {
                if (chartData[item.componentIndex].advanced === "proportion") {
                  let sumArray = chartData[item.componentIndex].yAxis.reduce(
                    (sum, num) => sum + num,
                    0
                  );
                  let count = ((item.value / sumArray) * 100).toFixed(2) + "%";
                  result += `${item.marker} ${item.seriesName} : ${count}</br>`;
                } else {
                  result += `${item.marker} ${item.seriesName} : ${item.value} </br>`;
                }
              } else {
                result += `${item.marker} ${item.seriesName} :  - </br>`;
              }
            });
            return result;
          },
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        legend: {
          data: legendList,
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },
        color: this.defaultColor,
        xAxis: {
          type: "category",
          data: chartData[0].xAxis,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          name: data.yAxis.title || "",
          min: data.yAxis.min || 0,
          max: data.yAxis.max || "dataMax",
        },
        series: dataList,
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    initChart2(data, chartData) {
      var chartDom = document.getElementById(this.id);

      var myChart = this.$echarts.init(chartDom);
      if (myChart) {
        myChart.clear();
      }
      let that = this;
      let markLineList = [];
      if (myChart) {
        myChart.clear();
      }
      let dataList = [];
      let stackPercentage = [];
      chartData.forEach((item) => {
        stackPercentage.push(item.yAxis);
      });
      stackPercentage = this.mergedArray(stackPercentage);
      data.yAxis.lines.forEach((item) => {
        let formatter = "";
        if (item.contentType === 1) {
          formatter = item.name;
        } else if (item.contentType === 2) {
          formatter = item.fixedValue;
        } else {
          formatter = `${item.name}: ${item.fixedValue}`;
        }
        markLineList.push({
          yAxis: item.fixedValue,
          label: {
            show: true,
            formatter: formatter,
            position: "start",
            distance: [-50, 0],
            color: item.color,
          },
          lineStyle: { normal: { color: item.color } },
        });
      });
      let sourceData = [];
      sourceData.push(chartData[0].xAxis);
      chartData[0].yAxis.forEach((item) => {
        sourceData.push(item);
      });

      for (let i = 0; i < sourceData[1].length - 1; i++) {
        dataList.push({
          name: sourceData[0][i + 1],
          type: "line",

          color: this.colorList[i]
            ? this.colorList[i].color
            : this.defaultColor[i],
          markLine: {
            silent: true,
            symbol: "none",

            data: markLineList,
          },

          itemStyle: {
            normal: {
              label: {
                show: data.yAxis.showData,
                position: "top",
                color: "#6f6f6f",
                formatter: function (d) {
                  if (chartData[0].advanced === "proportion") {
                    let sumArray = 0;
                    chartData[0].yAxis[d.dataIndex].forEach((item) => {
                      if (typeof item !== "string") {
                        sumArray += item;
                      }
                    });

                    let count =
                      (
                        (chartData[0].yAxis[d.dataIndex][d.componentIndex + 1] /
                          sumArray) *
                        100
                      ).toFixed(2) + "%";
                    return count;
                  } else {
                    return chartData[0].yAxis[d.dataIndex][
                      d.componentIndex + 1
                    ];
                  }
                },
              },
            },
          },
        });
      }

      var option;

      option = {
        legend: {
          show: data.yAxis.showLegend,
          y: data.yAxis.legendPositon,
          x: "center",
          textStyle: {
            color: "#6f6f6f",
          },
          bottom: "0%",
        },
        xAxis: {
          type: "category",

          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          name: data.yAxis.title || "",
          min: data.yAxis.min || 0,
          max: data.yAxis.max || "dataMax",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        dataset: {
          source: sourceData,
        },

        series: dataList,
      };

      option && myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
  created() {
    this.$bus.$on("deleteTargat", (data) => {
      this.colorList.splice(data, 1);
      this.colorList.forEach((item, index) => {
        this.$set(item, "colorIndex", index);
        if (!item.isSet) {
          this.$set(item, "color", this.defaultColor[index]);
        }
      });
      this.$bus.$emit(
        "getColorList",
        JSON.parse(JSON.stringify(this.colorList))
      );
    });
    this.$bus.$on("sortData", (data) => {
      // 从原数组中取出要移动的项
      const movedItem = this.colorList[data.oldIndex];

      // // 找到移动项在排序后数组的索引位置
      const sortedIndex = this.option.draggableObj.targatList.findIndex(
        (item) => item.id === movedItem.id
      );

      // // 根据 newIndex 插入到相应位置
      this.colorList.splice(sortedIndex, 1);
      this.colorList.splice(data.newIndex, 0, movedItem);

      this.$bus.$emit(
        "getColorList",
        JSON.parse(JSON.stringify(this.colorList))
      );
    });
    this.$bus.$on("addData", (data) => {
      if (this.colorList.length < 1) {
        this.colorList.push({
          label: data.value.__config__.restLabel,
          color: "",
          colorIndex: 0,
          id: data.value.__vModel__,
          isSet: false,
        });
      } else {
        let itemData = {
          label: data.value.__config__.restLabel,
          color: "",
          colorIndex: 0,
          id: data.value.__vModel__,
          isSet: false,
        };

        this.colorList.splice(data.obj.newIndex, 0, itemData);
      }
      this.colorList.forEach((item, index) => {
        this.$set(item, "colorIndex", index);
        if (!item.isSet) {
          this.$set(item, "color", this.defaultColor[index]);
        }
      });

      this.$bus.$emit(
        "getColorList",
        JSON.parse(JSON.stringify(this.colorList))
      );
    });
    this.$bus.$on("sortDimensionData", (data) => {
      if (this.type === "2") {
        this.colorList = [];
      }
    });
  },
  mounted() {
    if (this.chartId) {
      this.id = this.chartId;
    }
    this.getList(this.option, this.chartData);
  },
};
</script>

<style lang="scss" scoped>
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.line-warp {
  position: relative;
  height: 100%;
  width: 100%;

  #line {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-attachment: fixed;
  }
}
</style>
