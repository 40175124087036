<template>
  <div class="tools-box">
    <el-dialog
      :visible.sync="openDialog"
      width="338px"
      :modal="false"
      :before-close="imgHandleClose"
      class="setDialog"
      :style="position"
    >
      <div class="dialog-cont">
        <div class="lable">图片</div>
        <div class="tips">
          支持jpg、jpeg、png、gif格式，单张5MB以内
          {{ datas.data.file_list.length }}/10
        </div>
        <div
          style="max-height: 205px; overflow-y: auto"
          :class="datas.data.file_list.length >= 10 ? 'no-look' : ''"
        >
          <el-upload
            :action="action"
            list-type="picture-card"
            :limit="10"
            :file-list="datas.data.file_list"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="url + file.uid"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="handleEdit(file)"
                >
                  <i class="el-icon-edit"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
        <div class="lable">填充方式</div>
        <div>
          <el-radio-group v-model="datas.data.radio">
            <el-radio label="contain">原图居中</el-radio>
            <el-radio label="cover">居中填满</el-radio>
            <el-radio label="fill">拉伸填满</el-radio>
          </el-radio-group>
        </div>
        <div class="one-line">
          <div class="lable">自动轮播</div>
          <el-switch v-model="datas.data.auto"></el-switch>
        </div>
        <div class="one-line top" v-if="datas.data.auto === true">
          <div class="lable">轮播间隔(秒)</div>
          <div class="inputNum">
            <el-button
              style="padding: 3px"
              size="mini"
              icon="el-icon-minus"
              @click="subtractTime"
            ></el-button>
            <span style="margin: 0 10px">{{ datas.data.interval }}s</span>
            <el-button
              style="padding: 3px"
              size="mini"
              icon="el-icon-plus"
              @click="addTime"
            ></el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogImgDetails"
      width="338px"
      :modal="false"
      :before-close="imgDetailsHandleClose"
      class="setDialog"
      v-if="dialogImgDetails"
      :style="positionTow"
    >
      <div class="dialog-cont" v-if="dialogImgDetails">
        <div class="lable">图片</div>
        <el-image
          style="width: 100%; height: 129px"
          :src="url + nowFile.uid"
          fit="contain"
        ></el-image>
        <div class="lable">显示文案</div>
        <el-input
          type="textarea"
          resize="none"
          :rows="2"
          v-model="nowFile.text"
        ></el-input>
        <div class="lable">超链接</div>
        <el-input v-model="nowFile.link"></el-input>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    openDialog: false,
    datas: Object,
    position: "",
    positionTow: "",
  },
  data() {
    return {
      dialogImgDetails: false,
      url: "http://47.101.160.60:8787/api/upload/handleRequest?attachmen_id=",
      nowFile: {},
      img_data: {},
      action:
        process.env.NODE_ENV === "development"
          ? "/Api/api/upload/upload"
          : "http://123.206.196.73/api/upload/upload",
    };
  },
  watch: {},
  components: {},
  created() {},
  mounted() {
    this.url =
      process.env.NODE_ENV === "development"
        ? "http://47.101.160.60:8787/api/upload/handleRequest?attachmen_id="
        : `${window.location.origin}/api/upload/handleRequest?attachmen_id=`;
    this.action =
      process.env.NODE_ENV === "development"
        ? "/Api/api/upload/upload"
        : `${window.location.origin}/api/upload/upload`;
  },
  methods: {
    imgHandleClose() {
      this.$emit("handleClose", false);
    },
    imgDetailsHandleClose() {
      this.dialogImgDetails = false;
    },
    subtractTime() {
      if (this.datas.data.interval !== 1) {
        this.datas.data.interval--;
      }
    },
    addTime() {
      if (this.datas.data.interval !== 60) {
        this.datas.data.interval++;
      }
    },
    handleEdit(file) {
      this.dialogImgDetails = true;
      this.nowFile = file;
    },
    handleRemove(file) {
      this.datas.data.file_list.splice(
        this.datas.data.file_list.findIndex((item) => item.uid === file.uid),
        1
      );
    },
    uploadSuccess(response, file, fileList) {
      let obj = {
        uid: file.response.data.attachment_id,
        text: "",
        link: "",
      };
      this.datas.data.file_list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-look ::v-deep .el-upload--picture-card {
  display: none;
}

.top {
  margin: -5px 0 -20px 0;
}
</style>
