<template>
  <div class="echarts-warp">
    <div class="title">{{ settingOption.title }}</div>
    <div class="echarts-content">
      <plottingChart
        v-if="settingOption.chartsActive === 1"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
      ></plottingChart>
      <circularCharts
        v-else-if="settingOption.chartsActive === 2"
        :option="settingOption"
        :chartId="chartId"
        :chartData="chartData"
        :defaultColor="defaultColor"
        :colorList="colorList"
      ></circularCharts>
      <perspective
        v-else-if="settingOption.chartsActive === 3"
        :option="settingOption"
        :chartId="chartId"
        :chartData="chartData"
        :defaultColor="defaultColor"
        :colorList="colorList"
      ></perspective>
      <hstogramChart
        v-else-if="settingOption.chartsActive === 4"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :defaultColor="defaultColor"
        :colorList="colorList"
      ></hstogramChart>
      <barChart
        v-else-if="settingOption.chartsActive === 5"
        :option="settingOption"
        :chartId="chartId"
        :chartData="chartData"
        :colorList="colorList"
        :defaultColor="defaultColor"
      ></barChart>
      <lineChart
        v-else-if="settingOption.chartsActive === 6"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :defaultColor="defaultColor"
        :colorList="colorList"
      ></lineChart>
      <areaChart
        v-else-if="settingOption.chartsActive === 7"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :defaultColor="defaultColor"
        :colorList="colorList"
      ></areaChart>
      <pieChart
        v-else-if="settingOption.chartsActive === 11"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :colorList="colorList"
        :defaultColor="defaultColor"
      ></pieChart>
      <radarChart
        v-else-if="settingOption.chartsActive === 12"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :colorList="colorList"
        :defaultColor="defaultColor"
      ></radarChart>
      <funnelChart
        v-else-if="settingOption.chartsActive === 13"
        :option="settingOption"
        :chartData="chartData"
        :chartId="chartId"
        :colorList="colorList"
        :defaultColor="defaultColor"
      ></funnelChart>
    </div>
    <!-- <div v-else class="empty">
      <div v-if="targatLen > 0 && dimensionLen > 0">
        <i class="iconfont icon-box-o"></i>
        <div>暂无数据</div>
      </div>
      <div v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
    </div> -->
  </div>
</template>

<script>
import plottingChart from "./components/plottingChart.vue";
import hstogramChart from "./components/hstogramChart.vue";
import barChart from "./components/barChart.vue";
import lineChart from "./components/lineChart.vue";
import areaChart from "./components/areaChart.vue";
import pieChart from "./components/pieChart.vue";
import radarChart from "./components/radarChart.vue";
import funnelChart from "./components/funnelChart.vue";
import circularCharts from "./components/circularCharts.vue";
import perspective from "./components/perspective.vue";
import { getChartsSetting, getPerpectiveList } from "@/api/charts";
export default {
  data() {
    return {
      chartData: {},
      show: false,
      colorList: [],
      dimensionLen: 0,
      targatLen: 0,
      dimensionLineLen: 0,
      defaultColor: [
        "#63B2EE",
        "#76DA91",
        "#F8CB7F",
        "#F89588",
        "#7CD6CF",
        "#9192AB",
        "#7898E1",
        "#EFA666",
        "#EDDD86",
        "#9987CE",
      ],
    };
  },
  props: ["settingOption", "dataSourceId", "chartId"],
  components: {
    plottingChart,
    hstogramChart,
    barChart,
    lineChart,
    areaChart,
    pieChart,
    radarChart,
    funnelChart,
    circularCharts,
    perspective,
  },

  watch: {
    "settingOption.draggableObj": {
      handler(newVal) {
        this.dimensionLen = newVal.dimensionList.length;
        this.targatLen = newVal.targatList.length;
        this.dimensionLineLen = newVal.dimensionLineList.length;
        if (this.targatLen === 0 && this.dimensionLen === 0) {
          this.show = false;
        }

        if (this.settingOption.chartsActive === 3) {
          if (
            (this.targatLen > 0) &
            (this.dimensionLineLen > 0 || this.dimensionLen > 0)
          ) {
            getPerpectiveList(newVal).then((res) => {
              if (res.data) {
                this.chartData = res.data;
                this.show = true;
              } else {
                this.show = false;
              }
            });
          }
        } else {
          if (this.settingOption.chartsActive !== 2) {
            if (newVal.targatList.length > 0) {
              getChartsSetting(newVal).then((res) => {
                if (res.data[0].yAxis) {
                  this.chartData = res.data;
                  this.show = true;
                } else {
                  this.show = false;
                }
              });
            }
          } else {
            if (
              newVal.goalValueList.length === 1 &&
              newVal.targatList.length === 1
            ) {
              getChartsSetting(newVal).then((res) => {
                if (res.data[0].yAxis) {
                  this.chartData = res.data;
                  this.show = true;
                } else {
                  this.show = false;
                }
              });
            }
          }
        }
      },
      deep: true,
    },
    "settingOption.chartsActive": {
      handler(newVal) {
        if (this.dimensionLen === 1 && this.targatLen === 1) {
          this.colorList = [];
          this.clearColor();
        }
      },
      deep: true,
    },
    dimensionLen(val) {
      setTimeout(() => {
        this.setMultipleDimensionColor();
      }, 500);
    },

    "settingOption.draggableObj.dimensionList": {
      handler(newVal) {
        setTimeout(() => {
          this.setMultipleDimensionColor();
        }, 500);
      },
      deep: true,
    },
    "settingOption.draggableObj.goalValueList": {
      handler(newVal) {
        setTimeout(() => {
          if (
            newVal.length === 1 &&
            this.settingOption.draggableObj.targatList.length === 1
          ) {
            this.clearColor();
          }
        }, 500);
      },
      deep: true,
    },
    targatLen(val) {
      setTimeout(() => {
        this.setMultipleDimensionColor();
        if (
          val === 1 &&
          this.settingOption.draggableObj.goalValueList.length === 1
        ) {
          this.clearColor();
        }
      }, 500);
    },
  },
  methods: {
    clearColor() {
      let chartTypeList1 = [4, 5, 13];
      let len1 = this.settingOption.draggableObj.dimensionList.length;
      let len2 = this.settingOption.draggableObj.targatList.length;

      if (chartTypeList1.indexOf(this.settingOption.chartsActive) > -1) {
        if (len1 === 1 && len2 === 1) {
          if (this.colorList.length !== this.chartData[0].xAxis.length) {
            this.colorList = [];
            this.chartData[0].xAxis.forEach((item, index) => {
              this.colorList.push({
                label: item,
                color: this.defaultColor[0],
                colorIndex: index,
                id: item.__vModel__,
                isSet: false,
              });
            });

            this.$bus.$emit(
              "getColorList",
              JSON.parse(JSON.stringify(this.colorList))
            );
          }
        }
      }

      if (
        this.settingOption.chartsActive === 7 ||
        this.settingOption.chartsActive === 12 ||
        this.settingOption.chartsActive === 6
      ) {
        if (len1 === 1 && len2 === 1) {
          this.colorList = [];
          this.settingOption.draggableObj.targatList.forEach((item, index) => {
            this.colorList.push({
              label: item.__config__.restLabel,
              color: this.defaultColor[0],
              colorIndex: index,
              id: item.__vModel__,
              isSet: false,
            });
          });
          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        }
      }
      if (
        this.settingOption.chartsActive === 11 ||
        this.settingOption.chartsActive === 13
      ) {
        if (len1 === 1 && len2 === 1) {
          this.colorList = [];
          this.chartData[0].xAxis.forEach((item, index) => {
            this.colorList.push({
              label: item,
              color: this.defaultColor[index],
              colorIndex: index,
              id: item.__vModel__,
              isSet: false,
            });
          });

          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        }
      }
      if (this.settingOption.chartsActive === 2) {
        if (
          len2 === 1 &&
          this.settingOption.draggableObj.goalValueList.length === 1
        ) {
          if (
            this.colorList.length === 2 &&
            this.colorList[0].id === "jdt" &&
            this.colorList[1].id === "ds"
          ) {
            return false;
          }
          this.colorList = [];

          this.colorList.push({
            label: "进度条",
            color: this.defaultColor[0],
            colorIndex: 0,
            id: "jdt",
            isSet: false,
          });

          this.colorList.push({
            label: "底色",
            color: "#f0f6fa",
            colorIndex: 1,
            id: "ds",
            isSet: false,
          });

          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        }
      }
    },

    setMultipleDimensionColor() {
      if (
        this.settingOption.draggableObj.dimensionList.length === 2 &&
        this.settingOption.draggableObj.targatList.length === 1
      ) {
        let sourceData = [];
        sourceData.push(this.chartData[0].xAxis);
        this.chartData[0].yAxis.forEach((item) => {
          sourceData.push(item);
        });
        let arr = [];
        for (let i = 0; i < sourceData[1].length - 1; i++) {
          arr.push({
            label: sourceData[0][i + 1],
            color: this.defaultColor[i],
            colorIndex: i,
            id: sourceData[0][i + 1],
            isSet: false,
          });
        }

        if (this.colorList.length !== arr.length) {
          this.colorList = arr;
          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        }
      }
    },
  },
  created() {
    this.$bus.$on("deleteDimension", (data) => {
      setTimeout(() => {
        if (this.dimensionLen === 1) {
          this.colorList = [];
          this.clearColor();
        }
        if (this.dimensionLen === 2 && this.targatLen === 1) {
          this.colorList = [];
          this.setMultipleDimensionColor();
        }
      }, 400);
    });
    this.$bus.$on("addDimensionData", (data) => {
      setTimeout(() => {
        if (this.dimensionLen === 1 && this.targatLen > 0) {
          this.colorList = [];
          this.clearColor();
        }
        if (this.dimensionLen === 2 && this.targatLen === 1) {
          this.colorList = [];
          this.setMultipleDimensionColor();
        }
      }, 400);
    });
    this.$bus.$on("deleteTargat", (data) => {
      if (this.settingOption.chartsActive !== 13) {
        this.colorList.splice(data, 1);
        this.colorList.forEach((item, index) => {
          this.$set(item, "colorIndex", index);
          if (!item.isSet) {
            this.$set(item, "color", this.defaultColor[index]);
          }
        });

        this.$bus.$emit(
          "getColorList",
          JSON.parse(JSON.stringify(this.colorList))
        );
      }
      if (this.dimensionLen === 1 && this.targatLen > 0) {
        setTimeout(() => {
          this.clearColor();
        }, 400);
      }
    });
    this.$bus.$on("sortData", (data) => {
      let arr = [];

      this.settingOption.draggableObj.targatList.forEach((item, index) => {
        let i = this.colorList.findIndex((v) => v.id === item.__vModel__);
        arr.push(this.colorList[i]);
      });

      arr.forEach((item, index) => {
        if (item) {
          this.$set(item, "colorIndex", index);
          if (!item.isSet) {
            this.$set(item, "color", this.defaultColor[index]);
          }
        }
      });
      this.colorList = JSON.parse(JSON.stringify(arr));

      this.$bus.$emit(
        "getColorList",
        JSON.parse(JSON.stringify(this.colorList))
      );
    });
    this.$bus.$on("addData", (data) => {
      let len1 = this.settingOption.draggableObj.dimensionList.length;
      let len2 = this.settingOption.draggableObj.targatList.length;
      if (len2 > 1 && this.settingOption.chartsActive !== 13) {
        if (len1 === 1 && len2 === 2) {
          this.colorList = [];
          this.settingOption.draggableObj.targatList.forEach((item, index) => {
            this.colorList.push({
              label: item.__config__.restLabel,
              color: this.defaultColor[index],
              colorIndex: index,
              id: item.__vModel__,
              isSet: false,
            });
          });

          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        } else {
          let itemData = {
            label: data.value.__config__.restLabel,
            color: "",
            colorIndex: 0,
            id: data.value.__vModel__,
            isSet: false,
          };
          this.colorList.splice(data.obj.newIndex, 0, itemData);
          this.colorList.forEach((item, index) => {
            this.$set(item, "colorIndex", index);
            if (!item.isSet) {
              this.$set(item, "color", this.defaultColor[index]);
            }
          });
          this.$bus.$emit(
            "getColorList",
            JSON.parse(JSON.stringify(this.colorList))
          );
        }
      }
      if (this.dimensionLen === 1 && this.targatLen > 0) {
        setTimeout(() => {
          this.clearColor();
        }, 400);
      }
    });
    // this.$bus.$on("sortDimensionData", (data) => {
    //   if (this.type === "2") {
    //     this.colorList2 = [];
    //   }
    // });
  },
  mounted() {
    setTimeout(() => {
      if (
        this.settingOption &&
        this.settingOption.colorList &&
        this.settingOption.colorList.length > 0
      ) {
        this.colorList = JSON.parse(
          JSON.stringify(this.settingOption.colorList)
        );

        this.$bus.$emit(
          "getColorList",
          JSON.parse(JSON.stringify(this.colorList))
        );
      }
    }, 200);

    this.$bus.$on("updateColorList", (data) => {
      this.colorList = JSON.parse(JSON.stringify(data));
    });
    if (this.settingOption.chartsActive !== 2) {
      if (
        this.settingOption.draggableObj &&
        this.settingOption.draggableObj.targatList.length > 0
      ) {
        getChartsSetting(this.settingOption.draggableObj).then((res) => {
          this.chartData = res.data;
          this.show = true;
        });
      }
    } else {
      if (
        this.settingOption.draggableObj &&
        this.settingOption.draggableObj.targatList.length === 1 &&
        this.settingOption.draggableObj.goalValueList.length === 1
      ) {
        getChartsSetting(this.settingOption.draggableObj).then((res) => {
          this.chartData = res.data;
          this.show = true;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.echarts-warp {
  padding: 24px 24px 0 24px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #303133;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .echarts-content {
    height: calc(100% - 55px);
  }

  .empty {
    color: #838892;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;

    i {
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
}
</style>
