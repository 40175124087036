<template>
  <div>
    <el-drawer title="消息提醒" :visible="drawer" :direction="direction" :before-close="handleClose" size="90%">
      <div class="main">
        <div class="main_header">
          <ul>
            <li :class="type == 2 ? 'active' : ''" @click="handTpye(2)">
              全部
            </li>
            <li :class="type == 1 ? 'active' : ''" @click="handTpye(1)">
              已读
            </li>
            <li :class="type == 0 ? 'active' : ''" @click="handTpye(0)">
              未读
            </li>
          </ul>
          <span>全部标注已读</span>
        </div>
        <div class="main_item">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="title">
              <div class="left">
                <i class="iconfont icon-xiaoxi"></i>
                <span> 有待办未完成，请及时处理</span>
              </div>
              <span class="look" @click="handLook(item)">查看</span>
            </div>
            <ul>
              <li>事项名称:<span>这是事项名称</span></li>
              <li>
                截止时间:<span>2023年11月22日 15:20:45 剩余时间：10分钟</span>
              </li>
              <li>剩余时间:<span>10分钟</span></li>
            </ul>
          </div>
        </div>
      </div>
      <transactionDialog :onlyLook="onlyLook" :manageData="manageData" :showDialog.sync="showDialog">
      </transactionDialog>
    </el-drawer>

  </div>
</template>

<script>
import { getMessageList, getSetRead } from '@/api/app'
import transactionDialog from '@/views/toDoList/contentTable/transactionDialog/index.vue'
export default {
  data () {
    return {
      direction: "btt",
      type: 2,
      list: [],
      showDialog: false,
      onlyLook: false,
      manageData: {}
    };
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    transactionDialog
  },
  mounted () { this.getMessageListTmp() },
  created () {

  },
  methods: {
    handleClose () {
      this.$emit("update:drawer", false);
    },
    handTpye (e) {
      this.type = e;
      this.getMessageListTmp(this.type)
    },
    async getMessageListTmp (type) {
      try {
        const res = await getMessageList(type)
        if (res.code == 200) {
          this.list = res.data
        }
      } catch (error) {
        console.log('请求错误：', error);
      }

    },
    async handLook (e) {
      const res = await getSetRead(e._id)
      if (e.step_info.status == 2) {
        this.onlyLook = true
      }
      this.showDialog = true
      this.manageData = e.step_info
    }
  },
};
</script>

<style lang="scss" scoped>
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

::v-deep .el-drawer__header {
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #303133;
  line-height: 28px;
  padding-bottom: 10px;
}

::v-deep .el-drawer__body {
  background: #f5f8fc;
}

.main {
  height: 95%;
  background: #fff;
  margin: 50px 190px 0 190px;

  .main_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #ebeef5;

    ul {
      height: 100%;
      display: flex;

      li {
        cursor: pointer;
        text-align: center;
        line-height: 60px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #909399;
        line-height: 60px;
        margin: 0 20px;
        padding: 0px 10px;
      }

      .active {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #303133;
        border-bottom: 4px solid #409eff;
      }
    }

    span {
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #303133;
      line-height: 28px;
      margin-right: 20px;
    }
  }

  .main_item {
    padding: 32px;
    overflow-y: scroll;
    height: 82%;
    z-index: 9;

    .item {
      padding: 20px;
      margin-bottom: 20px;
      background: #ffffff;
      border: 1px solid #e4e7ed;
      border-radius: 4px;

      &:hover {
        background: #f5f8fc;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          .icon-xiaoxi {
            background: #409eff;
            border-radius: 2px;
            color: #fff;
          }

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #303133;
          }
        }

        .look {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #409eff;
          cursor: pointer;
        }
      }
    }

    ul {
      margin-top: 10px;

      li {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #909399;
        line-height: 28px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
