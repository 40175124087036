<template>
  <div class="plotting-warp">
    <div class="plotting-count" v-if="type === '1'">{{ count }}</div>
    <div class="plotting-ul" v-else-if="type === '2'">
      <div class="plotting-ul-count">
        <div class="name">共计</div>
        <div class="count">{{ sum }}</div>
      </div>
      <div class="plotting-ul-li" v-for="(item, index) in list" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="count">{{ item.value }}</div>
      </div>
    </div>
    <div class="empty" v-else>拖拽左侧字段到上方指标、目标值栏来添加数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",
      count: "",
      list: [],
      sum: 0,
    };
  },
  components: {},
  mounted() {},
  props: ["option", "chartData"],
  watch: {
    option: {
      handler(val) {
        setTimeout(() => {
          this.getList(this.option, this.chartData);
        }, 500);
      },
      deep: true,
    },
  },
  methods: {
    getList(data, chartData) {
      let len = data.draggableObj.dimensionList.length;
      let len2 = data.draggableObj.targatList.length;
      if (len === 0 && len2 === 1) {
        this.type = "1";
        this.count = this.chartData[0].yAxis[0];
      } else if (len === 1 && len2 === 1) {
        this.sum = 0;
        this.list = [];
        this.chartData[0].yAxis.forEach((item, index) => {
          this.sum += item;
          this.list.push({
            name: this.chartData[0].xAxis[index],
            value: item,
          });
        });
        this.type = "2";
      } else {
        this.type = "3";
      }
    },
  },
  mounted() {
    this.getList(this.option);
  },
};
</script>

<style lang="scss" scoped>
.plotting-warp {
  position: relative;
  height: 100%;
  width: 100%;
}
.empty {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.plotting-count {
  color: #838892;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 163.333px;
  color: #141e31;
}
.plotting-ul-count {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 0 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #b5b8be;
  .name {
    color: #2f7deb;
  }
  .count {
    color: #fff;
    padding: 0 12px;
    background: #f5c24d;
    box-sizing: border-box;
    line-height: 36px;
    border-radius: 24px;
  }
}
.plotting-ul {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
}
.plotting-ul::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.plotting-ul::-webkit-scrollbar-thumb {
  background: hsla(220, 6%, 54%, 0.5);
  border-radius: 3px;
}

.plotting-ul::-webkit-scrollbar-track {
  background: #f5f6f8;
  border-radius: 3px;
}
.plotting-ul .plotting-ul-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #141e31;
  padding: 10px 0;
  font-size: 14px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebecee;
  .count {
    padding: 0 12px;
    background: #eaf2fd;
    color: #2f7deb;
    box-sizing: border-box;
    line-height: 36px;
    border-radius: 24px;
  }
}
</style>
