var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-info"},[(!_vm.multi)?_c('div',{staticClass:"form-ul"},_vm._l((_vm.fileds),function(item,index){return _c('div',{key:index,staticClass:"form-li-warp",style:({
        width:
          item.__config__.tagIcon === 'row'
            ? '100%'
            : item.__config__.tagIcon === 'table'
              ? '100%'
              : '100%',
      })},[(item.__config__.tag === 'el-upload')?_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.__config__.label))]),_vm._l((_vm.getValue(item)),function(fileItem,fileIndex){return _c('div',{key:fileItem.uid},[_c('div',{staticClass:"li-value"},[_c('div',{staticClass:"file-list"},[_c('div',[_vm._v(_vm._s(fileItem.name))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.down(fileItem)}}},[_vm._v("查看下载")])],1)])])})],2):(item.__config__.tag === 'img-upload')?_c('div',[_c('div',[_vm._v(_vm._s(item.__config__.label))]),_c('div',{staticClass:"img-upload-warp"},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","list-type":"picture-card","on-preview":_vm.handlePreview,"file-list":_vm.getValue(item),"show-file-list":true,"disabled":true}}),_c('el-dialog',{attrs:{"visible":_vm.previewVisible,"before-close":_vm.handleClosePreview,"append-to-body":"","modal-append-to-body":false},on:{"update:visible":function($event){_vm.previewVisible=$event}}},[_c('img',{staticClass:"preview-image",attrs:{"src":_vm.previewImage}})])],1)]):(item.__config__.tag === 'el-editor')?_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.__config__.label))]),_c('div',{staticClass:"li-value",domProps:{"innerHTML":_vm._s(_vm.getValue(item))}})]):(item.__config__.tagIcon === 'row')?_c('div',{staticClass:"form-li",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(_vm._s(item.__config__.rowTitle))]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.getValue(item),"min-height":" 200","max-height":"500","header-cell-style":{
            background: '#F0F4FA',
            color: '#303133',
            borderRadius: '4px',
          }}},[_c('el-table-column',{attrs:{"type":"index","width":"55","label":"序号","align":"center"}}),_vm._l((item.__config__.children),function(itemChild,indexChild){return _c('el-table-column',{key:indexChild,attrs:{"label":itemChild.__config__.label,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(itemChild.__config__.tag !== 'el-upload')?_c('div',{staticClass:"row-class"},[(itemChild.__config__.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsTabelValue(
                      scope.row,
                      itemChild,
                      scope.$index
                    )}})]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.getTabsTabelValue( scope.row, itemChild, scope.$index ))+" ")])]):_c('div',{staticClass:"upload-list"},_vm._l((_vm.getTabsTabelValue(
                            scope.row,
                            itemChild,
                            scope.$index
                          )),function(uploadItem,uploadIndex){return _c('div',{key:uploadIndex,staticStyle:{"color":"#409eff","cursor":"pointer"},on:{"click":function($event){return _vm.fileDown(uploadItem)}}},[_vm._v(" "+_vm._s(uploadItem.name)+" ")])}),0)]}}],null,true)})})],2)],1):(item.__config__.tagIcon === 'table')?_c('div',{staticClass:"form-li",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_vm._v(_vm._s(item.__config__.label))]),_c('el-tabs',{staticStyle:{"width":"100%"},attrs:{"type":"border-card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.getTabs(item)),function(itemTab,indexTab){return _c('el-tab-pane',{key:indexTab,attrs:{"label":itemTab.name,"name":itemTab.name}},[_c('div',{staticClass:"tabs"},_vm._l((itemTab.children),function(itemChild,indexChild){return _c('div',{key:indexChild,staticClass:"tabs-ul",style:({
                  width:
                    itemChild.__config__.tagIcon === 'row'
                      ? '100%'
                      : itemChild.__config__.tagIcon === 'table'
                        ? '100%'
                        : '50%',
                })},[(itemChild.__config__.tag === 'el-upload')?_c('div',{staticClass:"tabs-li"},[_c('div',{staticClass:"li-title"},[_vm._v(_vm._s(itemChild.__config__.label))]),_vm._l((_vm.getTabsValue(itemChild)),function(uploadItem2,uploadIndex2){return _c('div',{key:uploadIndex2,staticClass:"li-upload-text"},[_c('div',[_vm._v(_vm._s(uploadItem2.name))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.down(uploadItem2)}}},[_vm._v("查看下载")])],1)})],2):(itemChild.__config__.tagIcon === 'Editor')?_c('div',{staticClass:"tabs-li"},[_c('div',[_vm._v(_vm._s(item.__config__.label))]),_c('div',{staticClass:"li-value",domProps:{"innerHTML":_vm._s(_vm.getTabsValue(itemChild))}})]):(itemChild.__config__.tagIcon === 'row')?_c('div',{staticClass:"tabs-li"},[_c('el-table',{attrs:{"data":_vm.getTabsValue(itemChild),"max-height":"500","header-cell-style":{
                      background: '#F0F4FA',
                      color: '#303133',
                      borderRadius: '4px',
                    }}},[_c('el-table-column',{attrs:{"type":"index","width":"55","label":"序号","align":"center"}}),_vm._l((itemChild.children),function(tableColumn,indexColumn){return _c('el-table-column',{key:indexColumn,attrs:{"label":tableColumn.label,"prop":tableColumn.fileds,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(tableColumn.__config__.tag !== 'el-upload')?_c('div',{staticClass:"row-class"},[(tableColumn.__config__.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsTabelValue(
                                scope.row,
                                tableColumn,
                                scope.$index
                              )}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabsTabelValue( scope.row, tableColumn, scope.$index ))+" ")])]):_c('div',{staticClass:"upload-list"},_vm._l((_vm.getTabsTabelValue(
                                      scope.row,
                                      tableColumn,
                                      scope.$index
                                    )),function(uploadItem,uploadIndex){return _c('div',{key:uploadIndex,staticStyle:{"color":"#409eff","cursor":"pointer"},on:{"click":function($event){return _vm.fileDown(uploadItem)}}},[_vm._v(" "+_vm._s(uploadItem.name)+" ")])}),0)]}}],null,true)})})],2)],1):_c('div',{staticClass:"tabs-li"},[_c('div',{staticClass:"li-title"},[_vm._v(_vm._s(itemChild.__config__.label))]),_c('div',{staticClass:"li-text"},[(itemChild.__config__.tagIcon === 'Sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabsValue(itemChild)}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabsValue(itemChild))+" ")])])])])}),0)])}),1),_c('div')],1):_c('div',{staticClass:"form-li"},[_c('div',[_vm._v(_vm._s(item.__config__.label))]),_c('div',{staticClass:"li-value"},[(item.__config__.tag === 'el-sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getValue(item)}})]):_c('div',[_vm._v(_vm._s(_vm.getValue(item)))])])])])}),0):_c('div',[_c('el-table',{ref:"table",attrs:{"data":_vm.data,"width":"100%","height":"100%","header-cell-style":{
        background: '#F0F4FA',
        color: '#303133',
        borderRadius: '4px',
      }}},[(_vm.isShowUserName)?_c('el-table-column',{key:"user",attrs:{"label":"提交人","prop":"user_name","align":"center","fixed":"left"}}):_vm._e(),_vm._l((_vm.fileds),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item.__config__.rowTitle ? item.__config__.rowTitle : item.__config__.label,"align":"center","width":"200","prop":item.__config__.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"col-class",attrs:{"title":_vm.getData(scope.row, item)}},[(item.__config__.tagIcon === 'Sign')?_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getData(scope.row, item)}}):(item.__config__.tagIcon === 'Editor')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getData(scope.row, item))}}):_c('div',[_vm._v(_vm._s(_vm.getData(scope.row, item)))])])]}}],null,true)},[(item.__config__.tagIcon === 'row')?_vm._l((item.__config__.children),function(item2,index2){return _c('el-table-column',{key:index2,attrs:{"label":item2.__config__.label,"width":"200","align":"center","prop":item2.__vModel__},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"row-warp"},_vm._l((scope.row[item.__config__.componentName]),function(item3,index3){return _c('div',{key:index3,staticClass:"row-class",attrs:{"title":_vm.getTabTableData(item3, item2)}},[_c('div',[_vm._v(" "+_vm._s(_vm.getTabTableData(item3, item2, scope.row[item.__config__.componentName]))+" ")])])}),0)]}}],null,true)})}):_vm._e(),(item.tagIcon === 'table')?_vm._l((item.child),function(tabItem,tabIndex){return _c('el-table-column',{key:tabIndex,attrs:{"align":"center","label":tabItem.name}},_vm._l((tabItem.child),function(tabChildItem,tabChildIndex){return _c('el-table-column',{key:tabChildIndex,attrs:{"width":"200","align":"center","label":tabChildItem.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(tabChildItem.tagIcon === 'Sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getData(
                      scope.row[item.fileds],
                      tabChildItem,
                      tabChildIndex
                    )}})]):(tabChildItem.tagIcon === 'Editor')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                    )}}):_c('div',[_vm._v(" "+_vm._s(_vm.getData(scope.row[item.fileds], tabChildItem, tabChildIndex))+" ")])]}}],null,true)},[(tabChildItem.tagIcon === 'row')?_c('div',_vm._l((tabChildItem.child),function(tableItem,tableIndex){return _c('el-table-column',{key:tableIndex,attrs:{"label":tableItem.label,"width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"row-warp"},_vm._l((scope.row[item.fileds][
                          tabChildItem.fileds
                        ]),function(item3,index3){return _c('div',{key:index3,staticClass:"row-class",attrs:{"title":_vm.getTabTableData(item3, tableItem)}},[(tableItem.tagIcon === 'Sign')?_c('div',[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.getTabTableData(item3, tableItem)}})]):_c('div',[_vm._v(" "+_vm._s(_vm.getTabTableData(item3, tableItem))+" ")])])}),0)]}}],null,true)})}),1):_vm._e()])}),1)}):_vm._e()],2)}),_c('el-table-column',{key:"time",attrs:{"label":"提交时间","prop":"add_time","align":"center","fixed":"right","width":_vm.isShowAddTime ? '200' : '-1'}}),_c('el-table-column',{attrs:{"min-width":"-1","align":"center","fixed":"right"}})],2),(!_vm.searchType)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[10, 20, 30, 50],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total,"background":""},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }