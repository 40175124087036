<template>
  <div class="img-grid">
    <el-carousel
      trigger="click"
      :autoplay="datas.data.auto"
      :interval="datas.data.interval * 1000"
    >
      <el-carousel-item
        v-for="(item, index) in datas.data.file_list"
        :key="index"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="url + item.uid"
          :fit="datas.data.radio"
          @click="gotoLink(item.link)"
        ></el-image>
        <div class="img-txt" v-if="item.text" @click="gotoLink(item.link)">
          {{ item.text }}
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    datas: Object,
  },
  data() {
    return {
      url: "",
    };
  },
  components: {},
  created() {
    console.log(this.datas);
  },
  mounted() {
    this.url =
      process.env.NODE_ENV === "development"
        ? "http://47.101.160.60:8787/api/upload/handleRequest?attachmen_id="
        : `${window.location.origin}/api/upload/handleRequest?attachmen_id=`;
  },
  methods: {
    gotoLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-grid {
  height: 100%;
}
::v-deep .el-carousel--horizontal,
::v-deep .el-carousel__container {
  height: 100% !important;
}
.img-txt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 5px 10px 20px 10px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  box-sizing: border-box;
}
</style>
