<!--  -->
<template>
  <div>
    <el-cascader
      style="width: 100%"
      :options="options"
      clearable
      v-model="address"
      @change="change"
      ref="cascader"
      :placeholder="placeholder"
    >
    </el-cascader>
    <el-input
      style="margin-top: 10px"
      type="textarea"
      :rows="3"
      placeholder="请输入详细地址"
      v-model="detailAddr"
      @blur="inputBlur"
    >
    </el-input>
  </div>
</template>

<script>
import { cityCode } from "@/utils/address";
export default {
  data() {
    return {
      options: cityCode,

      detailAddr: "",
      address: [],
    };
  },
  props: ["value", "valueList", "placeholder"],
  methods: {
    resetCasadeSelector() {
      if (this.$refs.cascader) {
        this.address = [];
        this.$refs.cascader.$refs.panel.activePath = [];
        this.$refs.cascader.$refs.panel.calculateCheckedNodePaths();
      }
    },

    change(val) {
      // val.forEach((item, index) => {
      //   this.$set(this.addressValue, index, item);
      // });

      let value = [];
      if (this.address.length > 0) {
        value = [];
        this.address.forEach((item, index) => {
          this.$set(value, index, item);
        });
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      } else {
        value = [];
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      }
      this.$emit("input", value);
    },
    inputBlur() {
      // this.$set(this.addressValue, 3, this.detailAddress);
      // console.log(this.addressValue);
      let value = [];
      if (this.address.length > 0) {
        value = [];
        this.address.forEach((item, index) => {
          this.$set(value, index, item);
        });
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      } else {
        value = [];
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      }
      this.$emit("input", value);
    },
    cityChange(data) {
      data.forEach((item) => {
        item.value = item.label;
        if (item.children) {
          this.cityChange(item.children);
        }
      });
    },
  },
  mounted() {
    this.resetCasadeSelector();
    setTimeout(() => {
      if (this.value) {
        if (this.value.length === 1) {
          this.detailAddr = this.value[0];
        } else if (this.value.length === 3) {
          this.value.forEach((item, index) => {
            this.$set(this.address, index, item);
          });
        } else if (this.value.length > 3) {
          this.value.forEach((item, index) => {
            if (index < 3) {
              this.$set(this.address, index, item);
            } else {
              this.detailAddr = item;
            }
          });
        }
      }
    }, 2000);
  },
  created() {
    this.cityChange(cityCode);
  },
};
</script>
<style lang='scss' scoped>
.address {
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  font-size: 14px;
  line-height: 32px;
  text-indent: 10px;
  font-weight: 400;
  color: #c0c4cc;
}
</style>
